import "./Conversations.css";
import "react-dropdown/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.min.css";

import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import Loading from "./Loading";
import moment from "moment";

export default function Activity(props) {
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [days, setDays] = useState([]);
  const [activities, setActivities] = useState([]);
  const [showDays, setShowDays] = useState("");

  useEffect(() => {
    async function onLoad() {
      setIsLoading(true);
      const activities = {};
      const days = [];
      const allContacts = await loadContacts(props.campaignId)
      try {

        const requests = allContacts.filter(
          (contact) =>
            "connectionRequestDate" in contact &&
            contact.connectionRequestDate !== "none"
        );

        requests.forEach(function (contact) {
          var date = moment(contact.connectionRequestDate)
            .startOf("day")
            .format()
            .split("T")[0];

          if (date in activities) {
            activities[date].push({
              type: "request",
              contact: contact,
            });
          } else {
            activities[date] = new Array({
              type: "request",
              contact: contact,
            });
            if (!days.includes(date)) days.push(date);
          }
        });

        const connections = allContacts.filter(
          (contact) =>
            "connectionDate" in contact && contact.connectionDate !== "none"
        );

        connections.forEach(function (contact) {
          var date = moment(contact.connectionDate)
            .startOf("day")
            .format()
            .split("T")[0];

          if (date in activities) {
            activities[date].push({
              type: "newConnection",
              contact: contact,
            });
          } else {
            activities[date] = new Array({
              type: "newConnection",
              contact: contact,
            });
            if (!days.includes(date)) days.push(date);
          }
        });

        const replies = allContacts.filter(
          (contact) =>
            "repliedDate" in contact && contact.repliedDate !== "none"
        );

        replies.forEach(function (contact) {
          var date = moment(contact.repliedDate)
            .startOf("day")
            .format()
            .split("T")[0];

          if (date in activities) {
            activities[date].push({
              type: "newReply",
              contact: contact,
            });
          } else {
            activities[date] = new Array({
              type: "newReply",
              contact: contact,
            });
            if (!days.includes(date)) days.push(date);
          }
        });

        const firstFollowUps = allContacts.filter(
          (contact) =>
            "firstFollowUpDate" in contact &&
            contact.firstFollowUpDate !== "none"
        );

        firstFollowUps.forEach(function (contact) {
          var date = moment(contact.firstFollowUpDate)
            .startOf("day")
            .format()
            .split("T")[0];

          if (date in activities) {
            activities[date].push({
              type: "followUp",
              contact: contact,
            });
          } else {
            activities[date] = new Array({
              type: "followUp",
              contact: contact,
            });
            if (!days.includes(date)) days.push(date);
          }
        });

        const secondFollowUps = allContacts.filter(
          (contact) =>
            "secondFollowUpDate" in contact &&
            contact.secondFollowUpDate !== "none"
        );

        secondFollowUps.forEach(function (contact) {
          var date = moment(contact.secondFollowUpDate)
            .startOf("day")
            .format()
            .split("T")[0];

          if (date in activities) {
            activities[date].push({
              type: "followUp",
              contact: contact,
            });
          } else {
            activities[date] = new Array({
              type: "followUp",
              contact: contact,
            });
            if (!days.includes(date)) days.push(date);
          }
        });

        console.log(days);

        const momentDays = days
          .map((day) => moment(day))
          .sort((a, b) => b - a)
          .map((day) => day.format("YYYY-MM-DD"));

        setActivities(activities);
        setDays(momentDays);
        setContacts(allContacts);
      } catch (e) {
        console.log(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated, props.accountId, props.campaignId]);

  function loadContacts(campaignId) {
    return API.get("contacts", `/campaigns/${campaignId}/contacts`);
  }

  function showActivity(day) {
    if (showDays.includes(day)) {
      const newDay = showDays.replace(day, "");
      setShowDays(newDay);
    } else {
      const newDay = showDays + day;
      setShowDays(newDay);
    }
  }

  return (
    <div className="Activity">
      {
        isLoading ? (
          <Loading />
        ) : contacts && contacts.length === 0 ? (
          <div>
            <Row>
              <Col sm={12}>
                <p>
                  We
                  will show you who we send connection requests to, who connects
                  with you and who replies.
              </p>
              </Col>
            </Row>
          </div>
        ) : (
              <Row>
                <br />
                {days.map((day, i) => (
                  <Col
                    xs={12}
                    key={day}
                    className='text-left'
                  >
                    <p>
                      <b>{moment(day).format("MMM Do")}</b>{" "}
                      <small>
                        {
                          activities[day].filter(
                            (activity) => activity.type === "request"
                          ).length
                        }{" "}
                        {activities[day].filter(
                          (activity) => activity.type === "request"
                        ).length === 1
                          ? "Request"
                          : "Requests"}{" "}
                  &middot;{" "}
                        {
                          activities[day].filter(
                            (activity) => activity.type === "newConnection"
                          ).length
                        }{" "}
                        {activities[day].filter(
                          (activity) => activity.type === "newConnection"
                        ).length === 1
                          ? "Connection"
                          : "Connections"}{" "}
                  &middot;{" "}
                        {
                          activities[day].filter(
                            (activity) => activity.type === "newReply"
                          ).length
                        }{" "}
                        {activities[day].filter(
                          (activity) => activity.type === "newReply"
                        ).length === 1
                          ? "Reply"
                          : "Replies"}{" "}
                  &middot;{" "}
                        {
                          activities[day].filter(
                            (activity) => activity.type === "followUp"
                          ).length
                        }{" "}
                        {activities[day].filter(
                          (activity) => activity.type === "followUp"
                        ).length === 1
                          ? "Followup"
                          : "Followups"}{" "}
                  &middot;{" "}
                        <span
                          onClick={() => showActivity(day)}
                          style={{ cursor: "pointer" }}
                          className="blue"
                        >
                          View Activity
                  </span>
                      </small>
                    </p>
                    <div
                      style={{ display: showDays.includes(day) ? "block" : "none" }}
                    >
                      {activities[day].filter(
                        (activity) => activity.type === "request"
                      ).length > 0 && (
                          <div>
                            <br />
                            <p>
                              We sent connection requests to{" "}
                              {activities[day]
                                .filter((activity) => activity.type === "request")
                                .map((activity) => (
                                  <span>
                                    {" "}
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={activity.contact.linkedinUrl}
                                    >
                                      {activity.contact.firstName}{" "}
                                      {activity.contact.lastName}
                                    </a>{" "}
                            &middot;
                                  </span>
                                ))}{" "}
                            </p>
                          </div>
                        )}

                      {activities[day].filter(
                        (activity) => activity.type === "followUp"
                      ).length > 0 && (
                          <div>
                            <br />
                            <p>
                              We sent follow ups to{" "}
                              {activities[day]
                                .filter((activity) => activity.type === "followUp")
                                .map((activity) => (
                                  <span>
                                    {" "}
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={activity.contact.linkedinUrl}
                                    >
                                      {activity.contact.firstName}{" "}
                                      {activity.contact.lastName}
                                    </a>{" "}
                            &middot;
                                  </span>
                                ))}{" "}
                            </p>
                          </div>
                        )}

                      {activities[day].filter(
                        (activity) => activity.type === "newConnection"
                      ).length > 0 && (
                          <div>
                            <br />
                            <p>
                              You connected with{" "}
                              {activities[day]
                                .filter((activity) => activity.type === "newConnection")
                                .map((activity) => (
                                  <span>
                                    {" "}
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={activity.contact.linkedinUrl}
                                    >
                                      {activity.contact.firstName}{" "}
                                      {activity.contact.lastName}
                                    </a>{" "}
                            &middot;
                                  </span>
                                ))}{" "}
                            </p>
                          </div>
                        )}

                      {activities[day].filter(
                        (activity) => activity.type === "newReply"
                      ).length > 0 && (
                          <div>
                            <br />
                            <p>
                              You received a reply from{" "}
                              {activities[day]
                                .filter((activity) => activity.type === "newReply")
                                .map((activity) => (
                                  <span>
                                    {" "}
                                    <a
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      href={activity.contact.linkedinUrl}
                                    >
                                      {activity.contact.firstName}{" "}
                                      {activity.contact.lastName}
                                    </a>{" "}
                            &middot;
                                  </span>
                                ))}{" "}
                            </p>
                          </div>
                        )}
                    </div>
                    <hr />
                  </Col>
                ))}
              </Row>
            )
      }
    </div >
  );
}
