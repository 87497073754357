import "./EditAccount.css";

import { ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import IosRefresh from "react-ionicons/lib/IosRefresh";
import LoaderButton from "../components/LoaderButton";

export default function EditAccount(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    function loadAccount() {
      return API.get("accounts", `/accounts/${props.match.params.id}`);
    }

    async function onLoad() {
      try {
        const account = await loadAccount();

        setEmail(account.email);
        setPassword(account.password);
        setFirstName(account.firstName);
        setLastName(account.lastName);
        setLinkedinUrl(account.linkedinUrl);
        setIsLoading(false);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.match.params.id]);

  function saveAccount(account) {
    return API.put("accounts", `/accounts/${props.match.params.id}`, {
      body: account,
    });
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const account = {
        email,
        password,
        firstName,
        lastName,
        linkedinUrl,
      };
      console.log(account);
      console.log(props.match.params.id);
      await saveAccount({
        email,
        password,
        firstName,
        lastName,
        linkedinUrl,
      });
      props.history.push(`/verify/${props.match.params.id}`);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  return (
    <div className="EditAccount">
      <br />
      <br />
      <div className="form-wrapper">
        <a className="other-btn" href={`/accounts/${props.match.params.id}`}>
          back
        </a>
        {!isLoading ? (
          <form onSubmit={handleSubmit}>
            <FormGroup className="hidden" controlId="linkedinUrl">
              <ControlLabel>LinkedIn Url</ControlLabel>
              <FormControl
                value={linkedinUrl}
                type="text"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormGroup>
            <br />
            <FormGroup className="hidden" controlId="email">
              <ControlLabel>Email</ControlLabel>
              <FormControl
                value={email}
                type="text"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <FormGroup controlId="password">
              <ControlLabel>LinkedIn Password</ControlLabel>
              <FormControl
                value={password}
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="hidden" controlId="firstName">
              <ControlLabel>First Name</ControlLabel>
              <FormControl
                value={firstName}
                type="text"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="hidden" controlId="lastName">
              <ControlLabel>Last Name</ControlLabel>
              <FormControl
                value={lastName}
                type="text"
                onChange={(e) => setLastName(e.target.value)}
              />
            </FormGroup>
            <LoaderButton
              type="submit"
              className="other-btn"
              isLoading={isLoading}
            >
              Update password
            </LoaderButton>
          </form>
        ) : (
          <IosRefresh fontSize="60px" color="#4800d8" rotate={true} />
        )}
      </div>
    </div>
  );
}
