const dev = {
  STRIPE_KEY: "pk_test_Hj5BKBh30bdMDEywa8pSAtR800VNyiSQY6",
  s3: {
    REGION: "us-east-1",
    BUCKET: "conversify-api-dev-attachmentsbucket-p79zj1u57qs0",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://ewvfbfhi8j.execute-api.us-east-1.amazonaws.com/dev",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_nNpYaPF2w",
    APP_CLIENT_ID: "55c7r21su7j4v5lv7abct2d870",
    IDENTITY_POOL_ID: "us-east-1:3f842eda-e29f-4a96-a5d4-eca4fa77f83f",
  },
};

const prod = {
  STRIPE_KEY: "pk_live_KPl3joeHycl1WUuO1VVWxccf00LOnq8DUF",
  s3: {
    REGION: "us-east-1",
    BUCKET: "conversify-api-prod-attachmentsbucket-1x24pslwb0lpu",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://k7z2u6590c.execute-api.us-east-1.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_ibvbKzbq0",
    APP_CLIENT_ID: "3nvgk4qulnfna1puiee1onf4m0",
    IDENTITY_POOL_ID: "us-east-1:4450d74d-9462-4a20-a92f-7bb0946366af",
  },
};

// Default to dev if not set
const config = process.env.NODE_ENV === "production" ? prod : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
