import { Col, Modal, Row, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import Loading from "./Loading";
import moment from "moment";
import Activity from "./Activity";
import { contactsInCamapign } from "../libs/contactsInCampaign";

export default function GodView(props) {
  const [campaigns, setCampaign] = useState(null);
  const [campaignId, setCampaignId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pausedContacts, setPausedContacts] = useState(0);
  const [pausedRequests, setPausedRequests] = useState(0);
  const [pausedConnections, setPausedConnections] = useState(0);
  const [pausedConvos, setPausedConvos] = useState(0);
  const [activeContacts, setActiveContacts] = useState(0);
  const [activeRequests, setActiveRequests] = useState(0);
  const [activeConnections, setActiveConnections] = useState(0);
  const [activeConvos, setActiveConvos] = useState(0);
  const [viewCampaignModal, setViewCampaignModal] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [loadingConvos, setLoadingConvos] = useState(false);
  const [profiles, setProfiles] = useState(false);

  useEffect(() => {
    function loadProfiles(refCode) {
      return API.post("profiles", `/allprofiles`, {
        body: {
          refCode,
        },
      });
    }

    function loadCampaigns() {
      return API.get("campaigns", `/godview`);
    }

    async function onLoad() {
      try {
        const profiles = await loadProfiles('brainy');
        const profileUserIds = []
        for(const profile_idx in profiles) {
          const profile = profiles[profile_idx];
          profileUserIds.push(profile.userId)
        }
        setProfiles(profiles)

        let campaigns = await loadCampaigns();
        campaigns = campaigns.filter(campaign => profileUserIds.includes(campaign.userId))

        setCampaign(campaigns);

        setPausedContacts(
          campaigns
            .filter((campaign) => campaign.paused === true)
            .reduce(function (a, b) {
              return a + b["contacts"];
            }, 0)
        );
        setPausedRequests(
          campaigns
            .filter((campaign) => campaign.paused === true)
            .reduce(function (a, b) {
              return a + b["requests"];
            }, 0)
        );
        setPausedConnections(
          campaigns
            .filter((campaign) => campaign.paused === true)
            .reduce(function (a, b) {
              return a + b["connections"];
            }, 0)
        );
        setPausedConvos(
          campaigns
            .filter((campaign) => campaign.paused === true)
            .reduce(function (a, b) {
              return a + b["conversations"];
            }, 0)
        );
        setActiveContacts(
          campaigns
            .filter((campaign) => campaign.paused !== true)
            .reduce(function (a, b) {
              return a + b["contacts"];
            }, 0)
        );
        setActiveRequests(
          campaigns
            .filter((campaign) => campaign.paused !== true)
            .reduce(function (a, b) {
              return a + b["requests"];
            }, 0)
        );
        setActiveConnections(
          campaigns
            .filter((campaign) => campaign.paused !== true)
            .reduce(function (a, b) {
              return a + b["connections"];
            }, 0)
        );
        setActiveConvos(
          campaigns
            .filter((campaign) => campaign.paused !== true)
            .reduce(function (a, b) {
              return a + b["conversations"];
            }, 0)
        );
        setIsLoading(true);
      } catch (e) {
        alert(e);
        
      }
    }

    onLoad();
  }, [props.match.params.id]);

  async function showViewCampaignModal(campaignId) {
    setViewCampaignModal(true);
    setLoadingConvos(true);
    setCampaignId(campaignId);
    const contacts = await contactsInCamapign(campaignId);
    console.log(contacts);
    setContacts(contacts);
    setLoadingConvos(false);
  }

  function closeModal() {
    setViewCampaignModal(false);
    setContacts([]);
  }


  return (
    <div className="GodView container">
      {isLoading ? (
        <div>
          <h3>Active</h3>
          <Row>
            <Col sm={3} xs={12}>
              <h4>{activeContacts}</h4>
              <p>Contacts Uploaded</p>
            </Col>
            <Col sm={3} xs={12}>
              <h4>
                {activeRequests}{" "}
                {activeContacts > 0 ? (
                  <small>
                    {((activeRequests / activeContacts) * 100).toFixed(0)}%
                  </small>
                ) : null}{" "}
              </h4>
              <p>Requests Sent</p>
            </Col>
            <Col sm={3} xs={12}>
              <h4>
                {activeConnections}{" "}
                {parseInt(activeContacts) > 0 ? (
                  <small>
                    {((activeConnections / activeContacts) * 100).toFixed(0)}%
                  </small>
                ) : null}{" "}
              </h4>
              <p>New Connections</p>
            </Col>
            <Col sm={3} xs={12}>
              <h4>
                {activeConvos}{" "}
                {activeContacts > 0 ? (
                  <small>
                    {((activeConvos / activeContacts) * 100).toFixed(0)}%
                  </small>
                ) : null}{" "}
              </h4>
              <p>Conversations</p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Campaign</th>
                    <th>Created</th>
                    <th>Account</th>
                    <th>Sending Limit</th>
                    <th>Contacts</th>
                    <th>Requests</th>
                    <th>Connections</th>
                    <th>Replies</th>
                    <th>Positives</th>
                  </tr>
                </thead>
                <tbody>
                  {campaigns
                    .sort((a, b) => b.createdAt - a.createdAt)
                    .filter((campaign) => campaign.paused !== true)
                    .map((campaign, i) => (
                      <tr key={i}>
                        <td
                          onClick={() =>
                            showViewCampaignModal(campaign.campaignId)
                          }
                        >
                          {campaign.title}
                        </td>
                        <td>{moment(campaign.createdAt).format("MMM Do")}</td>
                        <td>{campaign.username}</td>
                        <td>{campaign.dailyLimit}</td>
                        <td>{campaign.contacts}</td>
                        <td>{campaign.requests}</td>
                        <td>{campaign.connections}</td>
                        <td>{campaign.conversations}</td>
                        <td>{campaign.positives}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <h3>Paused</h3>
          <Row>
            <Col sm={3} xs={12}>
              <h4>{pausedContacts}</h4>
              <p>Contacts Uploaded</p>
            </Col>
            <Col sm={3} xs={12}>
              <h4>
                {pausedRequests}{" "}
                {pausedContacts > 0 ? (
                  <small>
                    {((pausedRequests / pausedContacts) * 100).toFixed(0)}%
                  </small>
                ) : null}{" "}
              </h4>
              <p>Requests Sent</p>
            </Col>
            <Col sm={3} xs={12}>
              <h4>
                {pausedConnections}{" "}
                {pausedContacts > 0 ? (
                  <small>
                    {((pausedConnections / pausedContacts) * 100).toFixed(0)}%
                  </small>
                ) : null}{" "}
              </h4>
              <p>New Connections</p>
            </Col>
            <Col sm={3} xs={12}>
              <h4>
                {pausedConvos}{" "}
                {pausedContacts > 0 ? (
                  <small>
                    {((pausedConvos / pausedContacts) * 100).toFixed(0)}%
                  </small>
                ) : null}{" "}
              </h4>
              <p>Conversations</p>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Campaign</th>
                    <th>Date</th>
                    <th>Template</th>
                    <th>Account</th>
                    <th>Sending Limit</th>
                    <th>Contacts</th>
                    <th>Requests</th>
                    <th>Connections</th>
                    <th>Replies</th>
                    <th>Positives</th>
                  </tr>
                </thead>
                <tbody>
                  {campaigns
                    .sort((a, b) => b.createdAt - a.createdAt)
                    .filter((campaign) => campaign.paused === true)
                    .reverse()
                    .map((campaign, i) => (
                      <tr key={i}>
                        <td
                          onClick={() =>
                            showViewCampaignModal(campaign.campaignId)
                          }
                        >
                          {campaign.title}
                        </td>
                        <td>{moment(campaign.createdAt).format("MMM Do")}</td>
                        <td>{campaign.templateId}</td>
                        <td>{campaign.username}</td>
                        <td>{campaign.dailyLimit}</td>
                        <td>{campaign.contacts}</td>
                        <td>{campaign.requests}</td>
                        <td>{campaign.connections}</td>
                        <td>{campaign.conversations}</td>
                        <td>{campaign.positives}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
          </Row>
          <br />
          <h3>Profiles</h3>
          <Row>
            {
              profiles.sort((a, b) => b.createdAt - a.createdAt).map(profile => (
                <Col xs={12}>
                  {profile.firstName} {profile.lastName} ({profile.email}) {moment(profile.createdAt).format("MMM Do")}
                  <br />
                  <br />
                </Col>
              ))
            }
          </Row>
          <Modal show={viewCampaignModal} dialogClassName="modal-90w">
            <Modal.Body>
              <div className="modal-card text-center">
                <Row>
                  <Col xs={12}>
                    <button onClick={() => closeModal()} className="other-btn">
                      close
                    </button>
                  </Col>
                </Row>
                <br />
                <Activity campaignId={campaignId} />
                {contacts.filter(
                  (contact) =>
                    contact.hasReplied === true &&
                    "conversationMessages" in contact &&
                    contact.conversationMessages.length > 0
                ).length > 0 ? (
                  <div className="all-convos">
                    {loadingConvos ? (
                      <Loading />
                    ) : (
                      contacts
                        .filter(
                          (contact) =>
                            contact.hasReplied === true &&
                            "conversationMessages" in contact &&
                            contact.conversationMessages.length > 0
                        )
                        .map((contact) => (
                          <div key={contact.contactId} className="text-left">
                            <br />
                            <h5>
                              {" "}
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href={contact.linkedinUrl}
                              >
                                <img
                                  height="15px"
                                  alt="linekdin logo"
                                  style={{
                                    position: "relative",
                                    bottom: "1px",
                                  }}
                                  src="https://s3.amazonaws.com/adsgen/linkedin.svg"
                                />
                              </a>{" "}
                              {contact.firstName} {contact.lastName}
                            </h5>
                            <br />
                            {contact.conversationMessages.map((msg, i) => (
                              <div key={i}>
                                <p>
                                  <b>
                                    {msg.user.firstName}{" "}
                                    {msg.user.lastName !== "none"
                                      ? msg.user.lastName
                                      : null}{" "}
                                  </b>
                                  <small>
                                    {moment(msg.createdAt).format(
                                      "MMM Do, h:mm:ss a"
                                    )}
                                  </small>
                                </p>
                                <p>{msg.content}</p>
                                <br />
                              </div>
                            ))}
                            <hr />
                          </div>
                        ))
                    )}
                  </div>
                ) : (
                  <div>
                    No conversations yet
                    <br />
                    <br />
                    {campaignId}
                    <br />
                    <br />
                    {contacts.length > 0
                      ? contacts[0].connectionRequestText
                      : null}
                    <br />
                    <br />
                    {contacts.length > 0 ? contacts[0].firstFollowUpText : null}
                    <br />
                    <br />
                    {contacts.length > 0
                      ? contacts[0].secondFollowUpText
                      : null}
                  </div>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}
