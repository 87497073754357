import "./EditTemplate.css";

import { ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import MdArrowBack from "react-ionicons/lib/MdArrowBack";
import ViewTemplate from "./ViewTemplate";

export default function EditTemplate(props) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [connectionRequestText, setConnectionRequestText] = useState("");
  const [firstFollowUpText, setFirstFollowUpText] = useState("none");
  const [firstFollowUpDays, setFirstFollowUpDays] = useState(1);
  const [hasFirstFollowup, setHasFirstFollowup] = useState(false);
  const [secondFollowUpText, setSecondFollowUpText] = useState("none");
  const [secondFollowUpDays, setSecondFollowUpDays] = useState(2);
  const [hasSecondFollowup, setHasSecondFollowup] = useState(false);
  const [thirdFollowUpText, setThirdFollowUpText] = useState("none");
  const [thirdFollowUpDays, setThirdFollowUpDays] = useState(2);
  const [hasThirdFollowup, setHasThirdFollowup] = useState(false);
  const [fourthFollowUpText, setFourthFollowUpText] = useState("none");
  const [fourthFollowUpDays, setFourthFollowUpDays] = useState(2);
  const [hasFourthFollowup, setHasFourthFollowup] = useState(false);
  const [fifthFollowUpText, setFifthFollowUpText] = useState("none");
  const [fifthFollowUpDays, setFifthFollowUpDays] = useState(2);
  const [hasFifthFollowup, setHasFifthFollowup] = useState(false);
  const [sixthFollowUpText, setSixthFollowUpText] = useState("none");
  const [sixthFollowUpDays, setSixthFollowUpDays] = useState(2);
  const [hasSixthFollowup, setHasSixthFollowup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showView, setShowView] = useState(false);

  useEffect(() => {
    function loadTemplate() {
      return API.get("templates", `/templates/${props.templateId}`);
    }

    async function onLoad() {
      try {
        const template = await loadTemplate();
        setTitle(template.title);
        setDescription(template.description);
        setConnectionRequestText(template.connectionRequestText);
        "firstFollowUpText" in template
          ? setFirstFollowUpText(template.firstFollowUpText)
          : setFirstFollowUpText("none");
        "firstFollowUpDays" in template
          ? setFirstFollowUpDays(template.firstFollowUpDays)
          : setFirstFollowUpDays("none");
        "hasFirstFollowup" in template
          ? setHasFirstFollowup(template.hasFirstFollowup)
          : setHasFirstFollowup(false);
        "secondFollowUpText" in template
          ? setSecondFollowUpText(template.secondFollowUpText)
          : setSecondFollowUpText("none");
        "secondFollowUpDays" in template
          ? setSecondFollowUpDays(template.secondFollowUpDays)
          : setSecondFollowUpDays("none");
        "hasSecondFollowup" in template
          ? setHasSecondFollowup(template.hasSecondFollowup)
          : setHasSecondFollowup(false);

        "thirdFollowUpText" in template
          ? setThirdFollowUpText(template.thirdFollowUpText)
          : setThirdFollowUpText("none");
        "thirdFollowUpDays" in template
          ? setThirdFollowUpDays(template.thirdFollowUpDays)
          : setThirdFollowUpDays("none");
        "hasThirdFollowup" in template
          ? setHasThirdFollowup(template.hasThirdFollowup)
          : setHasThirdFollowup(false);

        "fourthFollowUpText" in template
          ? setFourthFollowUpText(template.fourthFollowUpText)
          : setFourthFollowUpText("none");
        "fourthFollowUpDays" in template
          ? setFourthFollowUpDays(template.fourthFollowUpDays)
          : setFourthFollowUpDays("none");
        "hasFourthFollowup" in template
          ? setHasFourthFollowup(template.hasFourthFollowup)
          : setHasFourthFollowup(false);

        "fifthFollowUpText" in template
          ? setFifthFollowUpText(template.fifthFollowUpText)
          : setFifthFollowUpText("none");
        "fifthFollowUpDays" in template
          ? setFifthFollowUpDays(template.fifthFollowUpDays)
          : setFifthFollowUpDays("none");
        "hasFifthFollowup" in template
          ? setHasFifthFollowup(template.hasFifthFollowup)
          : setHasFifthFollowup(false);

        "sixthFollowUpText" in template
          ? setSixthFollowUpText(template.sixthFollowUpText)
          : setSixthFollowUpText("none");
        "sixthFollowUpDays" in template
          ? setSixthFollowUpDays(template.sixthFollowUpDays)
          : setSixthFollowUpDays("none");
        "hasSixthFollowup" in template
          ? setHasSixthFollowup(template.hasSixthFollowup)
          : setHasSixthFollowup(false);
        setIsLoading(false);
      } catch (e) {
        alert(e);
      }
    }

    onLoad();
  }, [props.templateId]);

  function validateForm() {
    return (
      title.length > 3 &&
      connectionRequestText.length > 5 &&
      connectionRequestText.length < 290 &&
      firstFollowUpText.length > 3
    );
  }

  function saveTemplate(template) {
    return API.put("templates", `/templates/${props.templateId}`, {
      body: template,
    });
  }

  function handleFirstFollowup() {
    setHasFirstFollowup(!hasFirstFollowup);
  }

  function handleSecondFollowup() {
    setHasSecondFollowup(!hasSecondFollowup);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await saveTemplate({
        title,
        description,
        connectionRequestText,
        firstFollowUpText,
        firstFollowUpDays,
        hasFirstFollowup,
        secondFollowUpText,
        secondFollowUpDays,
        hasSecondFollowup,
        thirdFollowUpText,
        thirdFollowUpDays,
        hasThirdFollowup,
        fourthFollowUpText,
        fourthFollowUpDays,
        hasFourthFollowup,
        fifthFollowUpText,
        fifthFollowUpDays,
        hasFifthFollowup,
        sixthFollowUpText,
        sixthFollowUpDays,
        hasSixthFollowup,
      });
      setIsLoading(false);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  return (
    <div>
      {showView ? (
        <ViewTemplate
          templateId={props.templateId}
          closeTemplateModel={props.closeTemplateModel}
        />
      ) : (
        <div className="EditTemplate">
          <span onClick={() => setShowView(true)} className="other-btn">
            <MdArrowBack fontSize="14px" />
            back
          </span>{" "}
          <span
            onClick={() => props.closeTemplateModel()}
            className="other-btn pull-right"
          >
            close
          </span>{" "}
          <br className="clearfix" />
          <br className="clearfix" />
          <br className="clearfix" />
          <p>
            <b>
              When you update a template you'll update all the campaigns that
              use that template.
            </b>
          </p>
          {!isLoading ? (
            <form onSubmit={handleSubmit}>
              <FormGroup controlId="title">
                <ControlLabel>Title</ControlLabel>
                <FormControl
                  value={title}
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </FormGroup>
              {connectionRequestText.length > 290 ? (
                <div className="warning-label">
                  <p className="text-center" style={{ position: "inherit" }}>
                    Your connection request template is too long
                  </p>
                </div>
              ) : null}
              <FormGroup controlId="connectionRequest">
                <ControlLabel>Connection Request</ControlLabel>
                <FormControl
                  value={connectionRequestText}
                  componentClass="textarea"
                  onChange={(e) => setConnectionRequestText(e.target.value)}
                />
              </FormGroup>
              <FormGroup controlId="hasFirstFollowup">
                <ControlLabel>Send Follow Up?</ControlLabel>
                <input
                  checked={hasFirstFollowup}
                  onChange={handleFirstFollowup}
                  type="checkbox"
                />
              </FormGroup>
              {hasFirstFollowup ? (
                <div>
                  <FormGroup controlId="firstFollowUpDays">
                    <ControlLabel>How many days after?</ControlLabel>
                    <FormControl
                      value={firstFollowUpDays}
                      type="number"
                      onChange={(e) => setFirstFollowUpDays(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup controlId="firstFollowUpText">
                    <ControlLabel>Followup Message</ControlLabel>
                    <FormControl
                      value={firstFollowUpText}
                      componentClass="textarea"
                      onChange={(e) => setFirstFollowUpText(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup controlId="hasSecondFollowup">
                    <ControlLabel>Send Another Follow Up?</ControlLabel>
                    <input
                      checked={hasSecondFollowup}
                      onChange={handleSecondFollowup}
                      type="checkbox"
                    />
                  </FormGroup>
                </div>
              ) : null}
              {hasSecondFollowup ? (
                <div>
                  <FormGroup controlId="secondFollowUpDays">
                    <ControlLabel>How many days after?</ControlLabel>
                    <FormControl
                      value={secondFollowUpDays}
                      type="number"
                      onChange={(e) => setSecondFollowUpDays(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup controlId="secondFollowUpText">
                    <ControlLabel>Second Followup Message</ControlLabel>
                    <FormControl
                      value={secondFollowUpText}
                      componentClass="textarea"
                      onChange={(e) => setSecondFollowUpText(e.target.value)}
                    />
                  </FormGroup>
                </div>
              ) : null}
              {hasThirdFollowup ? (
                <div>
                  <FormGroup controlId="thirdFollowUpDays">
                    <ControlLabel>How many days after?</ControlLabel>
                    <FormControl
                      value={thirdFollowUpDays}
                      type="number"
                      onChange={(e) => setThirdFollowUpDays(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup controlId="thirdFollowUpText">
                    <ControlLabel>Third Followup Message</ControlLabel>
                    <FormControl
                      value={thirdFollowUpText}
                      componentClass="textarea"
                      onChange={(e) => setThirdFollowUpText(e.target.value)}
                    />
                  </FormGroup>
                </div>
              ) : null}
              {hasFourthFollowup ? (
                <div>
                  <FormGroup controlId="fourthFollowUpDays">
                    <ControlLabel>How many days after?</ControlLabel>
                    <FormControl
                      value={fourthFollowUpDays}
                      type="number"
                      onChange={(e) => setFourthFollowUpDays(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup controlId="fourthFollowUpText">
                    <ControlLabel>Fourth Followup Message</ControlLabel>
                    <FormControl
                      value={fourthFollowUpText}
                      componentClass="textarea"
                      onChange={(e) => setFourthFollowUpText(e.target.value)}
                    />
                  </FormGroup>
                </div>
              ) : null}
              {hasFifthFollowup ? (
                <div>
                  <FormGroup controlId="fifthFollowUpDays">
                    <ControlLabel>How many days after?</ControlLabel>
                    <FormControl
                      value={fifthFollowUpDays}
                      type="number"
                      onChange={(e) => setFifthFollowUpDays(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup controlId="fifthFollowUpText">
                    <ControlLabel>Fifth Followup Message</ControlLabel>
                    <FormControl
                      value={fifthFollowUpText}
                      componentClass="textarea"
                      onChange={(e) => setFifthFollowUpText(e.target.value)}
                    />
                  </FormGroup>
                </div>
              ) : null}
              {hasSixthFollowup ? (
                <div>
                  <FormGroup controlId="sixthFollowUpDays">
                    <ControlLabel>How many days after?</ControlLabel>
                    <FormControl
                      value={sixthFollowUpDays}
                      type="number"
                      onChange={(e) => setSixthFollowUpDays(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup controlId="sixthFollowUpText">
                    <ControlLabel>Sixth Followup Message</ControlLabel>
                    <FormControl
                      value={sixthFollowUpText}
                      componentClass="textarea"
                      onChange={(e) => setSixthFollowUpText(e.target.value)}
                    />
                  </FormGroup>
                </div>
              ) : null}

              <LoaderButton
                className="other-btn"
                type="submit"
                isLoading={isLoading}
                disabled={!validateForm()}
              >
                Save
              </LoaderButton>
            </form>
          ) : (
            <img
              style={{ width: "100%" }}
              alt="loading-gif"
              src="https://conversify-marketing.s3.amazonaws.com/BrainyLabs.gif"
            />
          )}
        </div>
      )}
    </div>
  );
}
