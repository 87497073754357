import { Route, Switch } from "react-router-dom";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Dashboard from "./containers/Dashboard";
import EditAccount from "./containers/EditAccount";
import Admin from "./containers/Admin";
import Login from "./containers/Login";
import NotFound from "./containers/NotFound";
import Onboarding from "./containers/Onboarding";
import React from "react";
import ResetPassword from "./containers/ResetPassword";
import Signup from "./containers/Signup";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import VerifyAccount from "./containers/VerifyAccount";
import ViewAccount from "./containers/ViewAccount";

export default function Routes({ appProps }) {
  return (
    <Switch>
      <UnauthenticatedRoute
        path="/login"
        exact
        component={Login}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/r/:id"
        exact
        component={Signup}
        appProps={appProps}
      />
      <UnauthenticatedRoute
        path="/login/reset"
        exact
        component={ResetPassword}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/accounts/new"
        exact
        component={Onboarding}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/accounts/:id"
        exact
        component={ViewAccount}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/accounts/:id/edit"
        exact
        component={EditAccount}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/dashboard"
        exact
        component={Dashboard}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/verify/:id"
        exact
        component={VerifyAccount}
        appProps={appProps}
      />
      <AuthenticatedRoute
        path="/brainylabsadmin"
        exact
        component={Admin}
        appProps={appProps}
      />
      {/* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Switch>
  );
}
