import "./NewTemplate.css";

import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Row,
} from "react-bootstrap";
import React, { useState } from "react";

import { API } from "aws-amplify";
import MdArrowBack from "react-ionicons/lib/MdArrowBack";
import MdArrowDropleftCircle from "react-ionicons/lib/MdArrowDropleftCircle";
import MdArrowDroprightCircle from "react-ionicons/lib/MdArrowDroprightCircle";
import MdArrowForward from "react-ionicons/lib/MdArrowForward";
import MdCheckmark from "react-ionicons/lib/MdCheckmark";

export default function NewTemplate(props) {
  console.log(props);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [connectionRequestText, setConnectionRequestText] = useState(
    "Hi {{firstName}}, How much does a polar bear weigh? Enough to break the ice!"
  );
  const [firstFollowUpText, setFirstFollowUpText] = useState(
    "Thanks for connecting, {{firstName}}. Would love to get your advice on something I'm working on."
  );
  const [firstFollowUpDays, setFirstFollowUpDays] = useState(1);
  const [hasFirstFollowup, setHasFirstFollowup] = useState(false);
  const [secondFollowUpText, setSecondFollowUpText] = useState("");
  const [secondFollowUpDays, setSecondFollowUpDays] = useState(2);
  const [hasSecondFollowup, setHasSecondFollowup] = useState(false);
  const [thirdFollowUpText, setThirdFollowUpText] = useState("");
  const [thirdFollowUpDays, setThirdFollowUpDays] = useState(3);
  const [hasThirdFollowup, setHasThirdFollowup] = useState(false);
  const [fourthFollowUpText, setFourthFollowUpText] = useState("");
  const [fourthFollowUpDays, setFourthFollowUpDays] = useState(4);
  const [hasFourthFollowup, setHasFourthFollowup] = useState(false);
  const [fifthFollowUpText, setFifthFollowUpText] = useState("");
  const [fifthFollowUpDays, setFifthFollowUpDays] = useState(5);
  const [hasFifthFollowup, setHasFifthFollowup] = useState(false);
  const [sixthFollowUpText, setSixthFollowUpText] = useState("");
  const [sixthFollowUpDays, setSixthFollowUpDays] = useState(6);
  const [hasSixthFollowup, setHasSixthFollowup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState("title");
  const [templateCreated, setTemplateCreated] = useState(false);
  const [crIndex, setCrIndex] = useState(0);

  function validateForm() {
    return (
      title.length > 3 &&
      connectionRequestText.length > 5 &&
      Number.isInteger(parseInt(firstFollowUpDays)) &&
      Number.isInteger(parseInt(secondFollowUpDays)) &&
      Number.isInteger(parseInt(thirdFollowUpDays)) &&
      Number.isInteger(parseInt(fourthFollowUpDays)) &&
      Number.isInteger(parseInt(fifthFollowUpDays)) &&
      Number.isInteger(parseInt(sixthFollowUpDays))
    );
  }

  function crSsuggestions() {
    return [
      "Hi {{firstName}}, How much does a polar bear weigh? Enough to break the ice!",
      "Hi {{firstName}}, I don't know who you are. I don't know what you want. If you are looking for a sale I can tell you I don't have money, but what I do have are a very particular set of skills. Let's connect and I'll tell you more.",
      "Hi {{firstName}}, the Ben Franklin effect - when we do a person a favour, we tend to like them more as a result. Let me know if you need help with anything.",
      "Hi {{firstName}}, I’m launching a (free) platform later this month that might interest you. If you’d like to see more, let’s connect and I’ll show you.",
      "Hi {{firstName}}, I'm inviting a few {{company}} employees to a webinar we are hosting at the end of the month. Would you like me to add your name to the list?",
      "Hey {{firstName}}, We’ve never met but I am co-founder at one of {{location}}'s top growth agencies. I created a personalized video for you breaking down some ideas for your growth and ad strategy. Cool if I send it over?",
      "Hi {{firstName}}, this may sound random but I'll ask anyway - do you golf?",
      "Hi {{firstName}}, had some ideas around ambassador marketing. Thought this would be of interest to you, be great to connect.",
      "Hi {{firstName}}, I came across your profile today and would love to connect! I’ve partnered up with quite a few {{company}} employees across the country to help them with their finances.",
      "Hi {{firstName}}, suit guessing on LinkedIn is one of my favourite games. Is that a Harry Rosen suit you're wearing in your profile pic?",
      "Hi {{firstName}}, {{company}} came up in my research when looking for innovative teams that use {{custom1}}. I was wondering if I could get your feedback on a tool we've been building? I'm just trying to understand if there is a market for a what we're building.",
      "Hi {{firstName}}, have you ever wanted to learn how to code? I'm hosting an online workshop next week where I'll show people automate their LinkedIn account.",
    ];
  }

  function suggestCrFoward() {
    const crTemplates = crSsuggestions();
    let newIndex = crIndex + 1;
    if (newIndex === crTemplates.length) newIndex = 0;
    setConnectionRequestText(crTemplates[newIndex]);
    setCrIndex(newIndex);
  }

  function suggestCrBack() {
    const crTemplates = crSsuggestions();
    let newIndex = crIndex - 1;
    if (newIndex === -1) newIndex = crTemplates.length - 1;
    setConnectionRequestText(crTemplates[newIndex]);
    setCrIndex(newIndex);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      await createTemplate({
        title,
        description: "Sequence of LinkedIn messages",
        connectionRequestText,
        firstFollowUpText,
        firstFollowUpDays,
        hasFirstFollowup,
        secondFollowUpText,
        secondFollowUpDays,
        hasSecondFollowup,
        thirdFollowUpText,
        thirdFollowUpDays,
        hasThirdFollowup,
        fourthFollowUpText,
        fourthFollowUpDays,
        hasFourthFollowup,
        fifthFollowUpText,
        fifthFollowUpDays,
        hasFifthFollowup,
        sixthFollowUpText,
        sixthFollowUpDays,
        hasSixthFollowup,
      });
      setTemplateCreated(true);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function createTemplate(template) {
    return API.post("templates", "/templates", {
      body: template,
    });
  }

  function handleFirstFollowup() {
    setHasFirstFollowup(!hasFirstFollowup);
  }

  function handleSecondFollowup() {
    setHasSecondFollowup(!hasSecondFollowup);
  }

  function handleThirdFollowup() {
    setHasThirdFollowup(!hasThirdFollowup);
  }

  function handleFourthFollowup() {
    setHasFourthFollowup(!hasFourthFollowup);
  }

  function handleFifthFollowup() {
    setHasFifthFollowup(!hasFifthFollowup);
  }

  function handleSixthFollowup() {
    setHasSixthFollowup(!hasSixthFollowup);
  }

  function handleTitleValidation() {
    return title.length >= 5;
  }

  function handleDescriptionValidation() {
    console.log(Number.isInteger(parseInt(firstFollowUpDays)));
    return (
      description.length >= 10 && Number.isInteger(parseInt(firstFollowUpDays))
    );
  }

  function checkTemplateVariables(text) {
    const start = "{{";
    const finish = "}}";
    let index = -1;
    const starts = [];
    const finishes = [];
    const variables = [
      "firstName",
      "lastName",
      "position",
      "company",
      "location",
      "custom1",
      "custom2",
      "custom3",
      "custom4",
      "custom5",
      "custom6",
      "custom7",
      "custom8",
      "custom9",
      "custom10",
    ];

    let stopThis = true;

    while ((index = text.indexOf(start, index + 1)) > -1) {
      starts.push(index);
    }
    index = -1;
    while ((index = text.indexOf(finish, index + 1)) > -1) {
      finishes.push(index);
    }

    for (let i in starts) {
      const thisVariable = text.slice(starts[i] + 2, finishes[i]);
      if (!variables.includes(thisVariable)) {
        stopThis = false;
        break;
      }
    }
    return stopThis;
  }

  function handleRequestValidation() {
    return (
      connectionRequestText.length < 296 &&
      connectionRequestText.length > 10 &&
      checkTemplateVariables(connectionRequestText)
    );
  }

  return (
    <div className="NewTemplate">
      {templateCreated ? (
        <div className="text-center">
          <h1>
            <MdCheckmark fontSize="60px" color="#4800d8" />
          </h1>
          <p style={{ textAlign: "center" }}>Template created!</p>
          <p style={{ textAlign: "center" }}>
            <button
              style={{ margin: "auto", marginTop: "15px" }}
              onClick={() => props.setScreen("campaigns")}
              className="btn-secondary"
            >
              Create campaign
            </button>
          </p>
        </div>
      ) : (
        <Row>
          <Col sm={12}>
            <form onSubmit={handleSubmit}>
              {question === "title" ? (
                <FormGroup controlId="title">
                  <ControlLabel>What's the name of your template?</ControlLabel>
                  <FormControl
                    value={title}
                    type="text"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  <span
                    className="other-btn pull-right"
                    onClick={() => setQuestion("connectionRequest")}
                    disabled={!handleTitleValidation()}
                  >
                    Next
                    <MdArrowForward fontSize="16px" />
                  </span>
                </FormGroup>
              ) : null}

              {question === "description" ? (
                <FormGroup controlId="description">
                  <ControlLabel>
                    How would you describe your template?{" "}
                    <small>(for you to reference later)</small>
                  </ControlLabel>
                  <FormControl
                    value={description}
                    componentClass="textarea"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <span
                    className="other-btn pull-left"
                    onClick={() => setQuestion("title")}
                  >
                    <MdArrowBack fontSize="16px" />
                    Back
                  </span>
                  <span
                    className="other-btn pull-right"
                    onClick={() => setQuestion("connectionRequest")}
                    disabled={!handleDescriptionValidation()}
                  >
                    Next
                    <MdArrowForward fontSize="16px" />
                  </span>
                </FormGroup>
              ) : null}

              {question === "connectionRequest" ? (
                <FormGroup controlId="connectionRequest">
                  {!checkTemplateVariables(connectionRequestText) ? (
                    <div className="warning-label">
                      <p className="text-center">
                        One of your variables isn't right
                      </p>
                    </div>
                  ) : null}
                  {connectionRequestText.length > 296 ? (
                    <div className="warning-label">
                      <p className="text-center">
                        Your connection request template is too long
                      </p>
                    </div>
                  ) : null}
                  <ControlLabel>
                    Connection Request{" "}
                    <span
                      style={{
                        cursor: "pointer",
                        position: "relative",
                        bottom: "2px",
                      }}
                      onClick={() => suggestCrFoward()}
                      className="pull-right"
                    >
                      <MdArrowDroprightCircle color="#4800d8" fontSize="21px" />
                    </span>
                    <span
                      style={{
                        cursor: "pointer",
                        position: "relative",
                        bottom: "2px",
                      }}
                      onClick={() => suggestCrBack()}
                      className="pull-right"
                    >
                      <MdArrowDropleftCircle color="#4800d8" fontSize="21px" />
                    </span>{" "}
                  </ControlLabel>
                  <FormControl
                    value={connectionRequestText}
                    componentClass="textarea"
                    onChange={(e) => setConnectionRequestText(e.target.value)}
                  />
                  <span
                    className="other-btn pull-left"
                    onClick={() => setQuestion("title")}
                  >
                    <MdArrowBack fontSize="16px" />
                    Back
                  </span>
                  <button
                    className="other-btn pull-right"
                    onClick={() => setQuestion("hasFirstFollowup")}
                    disabled={!handleRequestValidation()}
                  >
                    Next
                    <MdArrowForward fontSize="16px" />
                  </button>
                </FormGroup>
              ) : null}

              {question === "hasFirstFollowup" ? (
                <div>
                  <FormGroup controlId="hasFirstFollowup">
                    <ControlLabel>
                      <b>Send a follow-up message?</b>{" "}
                      <input
                        checked={hasFirstFollowup}
                        onChange={handleFirstFollowup}
                        type="checkbox"
                      />
                    </ControlLabel>
                    <br />
                    <br />
                  </FormGroup>
                  {hasFirstFollowup ? (
                    <div>
                      {!Number.isInteger(parseInt(firstFollowUpDays)) ? (
                        <div className="warning-label">
                          <p className="text-center">
                            This needs to be a number
                          </p>
                        </div>
                      ) : null}
                      <FormGroup controlId="firstFollowUpDays">
                        <ControlLabel>
                          How many days after will you send that message?
                        </ControlLabel>
                        <FormControl
                          value={firstFollowUpDays}
                          type="number"
                          onChange={(e) => setFirstFollowUpDays(e.target.value)}
                        />
                      </FormGroup>
                      {!checkTemplateVariables(firstFollowUpText) ? (
                        <div className="warning-label">
                          <p className="text-center">
                            One of your variables isn't right
                          </p>
                        </div>
                      ) : null}
                      <FormGroup controlId="firstFollowUpText">
                        <ControlLabel>
                          What will your followup message be?
                        </ControlLabel>
                        <FormControl
                          value={firstFollowUpText}
                          componentClass="textarea"
                          onChange={(e) => setFirstFollowUpText(e.target.value)}
                        />
                      </FormGroup>
                      <br />
                      <FormGroup controlId="hasSecondFollowup">
                        <ControlLabel>
                          <b>Send another follow up message?</b>{" "}
                          <input
                            checked={hasSecondFollowup}
                            onChange={handleSecondFollowup}
                            type="checkbox"
                          />
                        </ControlLabel>
                        <br />
                        <br />
                      </FormGroup>
                    </div>
                  ) : null}

                  {hasSecondFollowup ? (
                    <div>
                      {!Number.isInteger(parseInt(secondFollowUpDays)) ? (
                        <div className="warning-label">
                          <p className="text-center">
                            This needs to be a number
                          </p>
                        </div>
                      ) : null}
                      <FormGroup controlId="secondFollowUpDays">
                        <ControlLabel>How many days after?</ControlLabel>
                        <FormControl
                          value={secondFollowUpDays}
                          type="number"
                          onChange={(e) =>
                            setSecondFollowUpDays(e.target.value)
                          }
                        />
                      </FormGroup>
                      {!checkTemplateVariables(secondFollowUpText) ? (
                        <div className="warning-label">
                          <p className="text-center">
                            One of your variables isn't right
                          </p>
                        </div>
                      ) : null}
                      <FormGroup controlId="secondFollowUpText">
                        <ControlLabel>Second Followup Message</ControlLabel>
                        <FormControl
                          value={secondFollowUpText}
                          componentClass="textarea"
                          onChange={(e) =>
                            setSecondFollowUpText(e.target.value)
                          }
                        />
                      </FormGroup>
                      <FormGroup controlId="hasThirdFollowup">
                        <ControlLabel>
                          <b>Send a third follow up message?</b>{" "}
                          <input
                            checked={hasThirdFollowup}
                            onChange={handleThirdFollowup}
                            type="checkbox"
                          />
                        </ControlLabel>
                        <br />
                        <br />
                      </FormGroup>
                      {hasThirdFollowup ? (
                        <div>
                          {!Number.isInteger(parseInt(thirdFollowUpDays)) ? (
                            <div className="warning-label">
                              <p className="text-center">
                                This needs to be a number
                              </p>
                            </div>
                          ) : null}
                          <FormGroup controlId="thirdFollowUpDays">
                            <ControlLabel>
                              How many days after the second message?
                            </ControlLabel>
                            <FormControl
                              value={thirdFollowUpDays}
                              type="number"
                              onChange={(e) =>
                                setThirdFollowUpDays(e.target.value)
                              }
                            />
                          </FormGroup>
                          {!checkTemplateVariables(thirdFollowUpText) ? (
                            <div className="warning-label">
                              <p className="text-center">
                                One of your variables isn't right
                              </p>
                            </div>
                          ) : null}
                          <FormGroup controlId="thirdFollowUpText">
                            <ControlLabel>Third Followup Message</ControlLabel>
                            <FormControl
                              value={thirdFollowUpText}
                              componentClass="textarea"
                              onChange={(e) =>
                                setThirdFollowUpText(e.target.value)
                              }
                            />
                          </FormGroup>
                          <FormGroup controlId="hasFourthFollowup">
                            <ControlLabel>
                              <b>Send a fourth follow up message?</b>{" "}
                              <input
                                checked={hasFourthFollowup}
                                onChange={handleFourthFollowup}
                                type="checkbox"
                              />
                            </ControlLabel>
                            <br />
                            <br />
                          </FormGroup>
                          {hasFourthFollowup ? (
                            <div>
                              {!Number.isInteger(
                                parseInt(fourthFollowUpDays)
                              ) ? (
                                <div className="warning-label">
                                  <p className="text-center">
                                    This needs to be a number
                                  </p>
                                </div>
                              ) : null}
                              <FormGroup controlId="fourthFollowUpDays">
                                <ControlLabel>
                                  How many days after the third message?
                                </ControlLabel>
                                <FormControl
                                  value={fourthFollowUpDays}
                                  type="number"
                                  onChange={(e) =>
                                    setFourthFollowUpDays(e.target.value)
                                  }
                                />
                              </FormGroup>
                              {!checkTemplateVariables(fourthFollowUpText) ? (
                                <div className="warning-label">
                                  <p className="text-center">
                                    One of your variables isn't right
                                  </p>
                                </div>
                              ) : null}
                              <FormGroup controlId="fourthFollowUpText">
                                <ControlLabel>
                                  Fourth Followup Message
                                </ControlLabel>
                                <FormControl
                                  value={fourthFollowUpText}
                                  componentClass="textarea"
                                  onChange={(e) =>
                                    setFourthFollowUpText(e.target.value)
                                  }
                                />
                              </FormGroup>
                              <FormGroup controlId="hasFifthFollowup">
                                <ControlLabel>
                                  <b>Send a fifth follow up message?</b>{" "}
                                  <input
                                    checked={hasFifthFollowup}
                                    onChange={handleFifthFollowup}
                                    type="checkbox"
                                  />
                                </ControlLabel>
                                <br />
                                <br />
                              </FormGroup>
                              {hasFifthFollowup ? (
                                <div>
                                  {!Number.isInteger(
                                    parseInt(fifthFollowUpDays)
                                  ) ? (
                                    <div className="warning-label">
                                      <p className="text-center">
                                        This needs to be a number
                                      </p>
                                    </div>
                                  ) : null}
                                  <FormGroup controlId="fifthFollowUpDays">
                                    <ControlLabel>
                                      How many days after the fourth message?
                                    </ControlLabel>
                                    <FormControl
                                      value={fifthFollowUpDays}
                                      type="number"
                                      onChange={(e) =>
                                        setFifthFollowUpDays(e.target.value)
                                      }
                                    />
                                  </FormGroup>
                                  {!checkTemplateVariables(
                                    fifthFollowUpText
                                  ) ? (
                                    <div className="warning-label">
                                      <p className="text-center">
                                        One of your variables isn't right
                                      </p>
                                    </div>
                                  ) : null}
                                  <FormGroup controlId="fifthFollowUpText">
                                    <ControlLabel>
                                      Fifth Followup Message
                                    </ControlLabel>
                                    <FormControl
                                      value={fifthFollowUpText}
                                      componentClass="textarea"
                                      onChange={(e) =>
                                        setFifthFollowUpText(e.target.value)
                                      }
                                    />
                                  </FormGroup>
                                  <FormGroup controlId="hasSixthFollowup">
                                    <ControlLabel>
                                      <b>Send a sixth follow up message?</b>{" "}
                                      <input
                                        checked={hasSixthFollowup}
                                        onChange={handleSixthFollowup}
                                        type="checkbox"
                                      />
                                    </ControlLabel>
                                    <br />
                                    <br />
                                  </FormGroup>
                                  {hasSixthFollowup ? (
                                    <div>
                                      {!Number.isInteger(
                                        parseInt(sixthFollowUpDays)
                                      ) ? (
                                        <div className="warning-label">
                                          <p className="text-center">
                                            This needs to be a number
                                          </p>
                                        </div>
                                      ) : null}
                                      <FormGroup controlId="sixthFollowUpDays">
                                        <ControlLabel>
                                          How many days after the sixth message?
                                        </ControlLabel>
                                        <FormControl
                                          value={sixthFollowUpDays}
                                          type="number"
                                          onChange={(e) =>
                                            setSixthFollowUpDays(e.target.value)
                                          }
                                        />
                                      </FormGroup>
                                      {!checkTemplateVariables(
                                        sixthFollowUpText
                                      ) ? (
                                        <div className="warning-label">
                                          <p className="text-center">
                                            One of your variables isn't right
                                          </p>
                                        </div>
                                      ) : null}
                                      <FormGroup controlId="sixthFollowUpText">
                                        <ControlLabel>
                                          Sixth Followup Message
                                        </ControlLabel>
                                        <FormControl
                                          value={sixthFollowUpText}
                                          componentClass="textarea"
                                          onChange={(e) =>
                                            setSixthFollowUpText(e.target.value)
                                          }
                                        />
                                      </FormGroup>
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              ) : null}

              {question === "hasFirstFollowup" ? (
                <FormGroup controlId="hasFirstFollowup">
                  <span
                    className="other-btn pull-left"
                    onClick={() => setQuestion("connectionRequest")}
                  >
                    <MdArrowBack fontSize="16px" />
                    Back
                  </span>
                  <span
                    className="other-btn pull-right"
                    onClick={() => setQuestion("previewTemplate")}
                    disabled={!handleDescriptionValidation()}
                  >
                    Next
                    <MdArrowForward fontSize="16px" />
                  </span>
                </FormGroup>
              ) : null}

              <div className="text-center">
                {question === "previewTemplate" ? (
                  <div>
                    <div>
                      <p>
                        <b>Connection Request</b>
                      </p>
                      <p>{connectionRequestText}</p>
                    </div>
                    {hasFirstFollowup ? (
                      <p className="text-left">
                        <br />
                        <small className="blue">
                          wait {firstFollowUpDays}{" "}
                          {firstFollowUpDays === 1 ? "day" : "days"} after they
                          connect with you and don't reply
                        </small>
                        <br />
                        <br />
                      </p>
                    ) : null}
                    {hasFirstFollowup ? (
                      <div>
                        <p>
                          <b>Follow-up</b>
                        </p>
                        <p>{firstFollowUpText}</p>
                      </div>
                    ) : null}
                    {hasSecondFollowup ? (
                      <p className="text-left">
                        <br />
                        <small className="blue">
                          wait {secondFollowUpDays}{" "}
                          {secondFollowUpDays === 1 ? "day" : "days"}
                        </small>
                        <br />
                        <br />
                      </p>
                    ) : null}
                    {hasSecondFollowup ? (
                      <div>
                        <p>
                          <b>Second Follow-up</b>
                        </p>
                        <p>{secondFollowUpText}</p>
                      </div>
                    ) : null}
                    {hasThirdFollowup ? (
                      <p className="text-left">
                        <br />
                        <small className="blue">
                          wait {thirdFollowUpDays}{" "}
                          {thirdFollowUpDays === 1 ? "day" : "days"}
                        </small>
                        <br />
                        <br />
                      </p>
                    ) : null}
                    {hasThirdFollowup ? (
                      <div>
                        <p>
                          <b>Third Follow-up</b>
                        </p>
                        <p>{thirdFollowUpText}</p>
                      </div>
                    ) : null}
                    {hasFourthFollowup ? (
                      <p className="text-left">
                        <br />
                        <small className="blue">
                          wait {fourthFollowUpDays}{" "}
                          {fourthFollowUpDays === 1 ? "day" : "days"}
                        </small>
                        <br />
                        <br />
                      </p>
                    ) : null}
                    {hasFourthFollowup ? (
                      <div>
                        <p>
                          <b>Fourth Follow-up</b>
                        </p>
                        <p>{fourthFollowUpText}</p>
                      </div>
                    ) : null}
                    {hasFifthFollowup ? (
                      <p className="text-left">
                        <br />
                        <small className="blue">
                          wait {fifthFollowUpDays}{" "}
                          {fifthFollowUpDays === 1 ? "day" : "days"}
                        </small>
                        <br />
                        <br />
                      </p>
                    ) : null}
                    {hasFifthFollowup ? (
                      <div>
                        <p>
                          <b>Fifth Follow-up</b>
                        </p>
                        <p>{fifthFollowUpText}</p>
                      </div>
                    ) : null}
                    {hasSixthFollowup ? (
                      <p className="text-left">
                        <br />
                        <small className="blue">
                          wait {sixthFollowUpDays}{" "}
                          {sixthFollowUpDays === 1 ? "day" : "days"}
                        </small>
                        <br />
                        <br />
                      </p>
                    ) : null}
                    {hasSixthFollowup ? (
                      <div>
                        <p>
                          <b>Sixth Follow-up</b>
                        </p>
                        <p>{sixthFollowUpText}</p>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>

              {question === "previewTemplate" ? (
                <div>
                  <span
                    className="other-btn pull-left"
                    onClick={() => setQuestion("hasFirstFollowup")}
                  >
                    <MdArrowBack fontSize="16px" />
                    Back
                  </span>
                  <button
                    className="other-btn pull-right"
                    type="submit"
                    disabled={!validateForm() || isLoading}
                  >
                    Create Template
                    <MdArrowForward fontSize="16px" />
                  </button>
                </div>
              ) : null}
            </form>
            <br />
            {question === "connectionRequest" ||
            (hasFirstFollowup && question === "hasFirstFollowup") ? (
              <div className="text-left">
                <h5>You can use these variables to in your template</h5>
                <Row>
                  {[
                    "firstName",
                    "lastName",
                    "position",
                    "company",
                    "location",
                    "custom1",
                    "custom2",
                    "custom3",
                    "custom4",
                    "custom5",
                    "custom6",
                    "custom7",
                    "custom8",
                    "custom9",
                    "custom10",
                  ].map((item) => (
                    <Col key={item} xs={3}>
                      {`{{${item}}}`} <br />
                      <br />
                    </Col>
                  ))}
                </Row>
              </div>
            ) : null}
          </Col>
        </Row>
      )}
    </div>
  );
}
