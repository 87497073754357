import "./App.css";

import { Link, withRouter } from "react-router-dom";
import { Nav, NavItem, Navbar } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { Auth } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "./Routes";

function App(props) {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    props.history.push("/login");
  }

  return (
    !isAuthenticating && (
      <div className="App">
        {isAuthenticated ? null : (
          <Navbar
            style={{
              background: "white",
            }}
            collapseOnSelect
          >
            <Navbar.Header>
              <Navbar.Brand>
                <Link to="/r/home">
                  <img
                    style={{ position: "relative", bottom: "15px" }}
                    height="50"
                    alt="conversify logo"
                    src="https://conversify-marketing.s3.amazonaws.com/brainylabs.png"
                  />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
              {isAuthenticated ? null : (
                <Nav pullRight>
                  <LinkContainer to="/login">
                    <NavItem>Log In</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/r/nav">
                    <NavItem>Sign Up</NavItem>
                  </LinkContainer>
                </Nav>
              )}
            </Navbar.Collapse>
          </Navbar>
        )}

        <Routes
          appProps={{
            isAuthenticated,
            userHasAuthenticated,
            handleLogout,
          }}
        />
        {isAuthenticated ? (
          <div className="footer">
            <p>
              <small>
                <a href="/dashboard">Brainylabs Inc.</a> |{" "}
                <span onClick={() => handleLogout()}>logout</span>
              </small>
            </p>
          </div>
        ) : null}
      </div>
    )
  );
}

export default withRouter(App);
