import "./BillingForm.css";

import { CardElement, injectStripe } from "react-stripe-elements";
import { ControlLabel, FormControl, FormGroup } from "react-bootstrap";
import React, { Component } from "react";

import LoaderButton from "./LoaderButton";

class BillingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      isProcessing: false,
      isCardComplete: false,
      accounts: 1,
    };
  }

  validateForm() {
    return (
      this.state.name !== "" &&
      this.state.isCardComplete &&
      this.state.accounts > 0
    );
  }

  handleFieldChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleCardFieldChange = (event) => {
    this.setState({
      isCardComplete: event.complete,
    });
  };

  handleSubmitClick = async (event) => {
    event.preventDefault();

    const { name, accounts } = this.state;

    this.setState({ isProcessing: true });

    const { token, error } = await this.props.stripe.createToken({ name });

    this.setState({ isProcessing: false });

    this.props.onSubmit({ accounts, token, error });
  };

  render() {
    const loading = this.state.isProcessing || this.props.loading;

    return (
      <form className="BillingForm" onSubmit={this.handleSubmitClick}>
        <FormGroup bsSize="large" controlId="accounts">
          <ControlLabel style={{ marginTop: "0px" }}>
            Numbers of accounts
          </ControlLabel>
          <FormControl
            type="number"
            value={this.state.accounts}
            onChange={this.handleFieldChange}
          />
        </FormGroup>
        <p>
          <small>
            You'll be charged ${this.state.accounts * 45} USD per month for{" "}
            {this.state.accounts}{" "}
            {this.state.accounts === 1 ? "account" : "accounts"}.
          </small>
        </p>
        <hr />
        <FormGroup bsSize="large" controlId="name">
          <FormControl
            type="text"
            value={this.state.name}
            onChange={this.handleFieldChange}
            placeholder="Name on the card"
          />
        </FormGroup>
        <CardElement
          className="card-field"
          onChange={this.handleCardFieldChange}
          style={{
            base: { fontSize: "18px", fontFamily: '"Open Sans", sans-serif' },
          }}
        />
        <LoaderButton
          block
          bsSize="large"
          type="submit"
          text="Purchase"
          isLoading={loading}
          loadingtext="Purchasing…"
          disabled={!this.validateForm()}
        >
          Purchase
        </LoaderButton>
      </form>
    );
  }
}

export default injectStripe(BillingForm);
