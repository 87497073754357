import "./Conversations.css";
import "react-dropdown/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.min.css";

import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Modal,
  Row,
} from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API } from "aws-amplify";
import Dropdown from "react-dropdown";
import LoaderButton from "../components/LoaderButton";
import Loading from "./Loading";
import MdCheckmark from "react-ionicons/lib/MdCheckmark";
import IosArrowForward from "react-ionicons/lib/IosArrowForward";
import MdEye from "react-ionicons/lib/MdEye";
import axios from "axios";
import moment from "moment";

export default function Contacts(props) {
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentContact, setCurrentContact] = useState({});
  const [campaign, setCampaign] = useState(null);
  const [campaigns, setCampaigns] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [selectedContacts, setSelectedContacts] = useState(null);
  const [messageText, setMessageText] = useState("");
  const [messageLoad, setMessageLoad] = useState(false);
  const [messageSentiment, setMessageSentiment] = useState("POSITIVE");
  const [viewUpdateProfileModal, setUpdateProfileModal] = useState(null);
  const [ibmId, setIbmId] = useState(null);
  const [ibmUrl, setIbmUrl] = useState(null);
  const [ibmAuthenticator, setIbmAuthenticator] = useState(null);
  const [integrated, setIntegrated] = useState(false);
  const [loadingIntegration, setLoadingIntegration] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [viewTutorial, setViewTutorial] = useState(false);
  const [unlabledContact, setUnlabledContact] = useState(false);
  const [unlabledContacts, setUnlabledContacts] = useState(0);
  const [isLabeling, setIsLabeling] = useState(false);

  useEffect(() => {
    async function onLoad() {
      try {
        let campaigns = props.campaigns.filter(
          (campaign) =>
            campaign.accountId === props.accountId && campaign.conversations > 0
        );
        let contacts = await loadContacts(campaigns[0].campaignId);
        contacts = contacts.filter(
          (contact) =>
            contact.hasReplied === true &&
            "conversationMessages" in contact &&
            contact.conversationMessages.length > 0
        );
        let goodCampaigns = [];
        for (let campaignIndex in campaigns) {
          const value = campaigns[campaignIndex].campaignId;
          const label = campaigns[campaignIndex].title;
          goodCampaigns.push({
            label,
            value,
          });
        }
        if (goodCampaigns.length > 0) {
          setCampaigns(goodCampaigns);
          setSelectedCampaign(goodCampaigns[0]);
          const coolCampaign = await loadCampaign(goodCampaigns[0].value);
          setCampaign(coolCampaign);
          if ("ibmAuthenticator" in coolCampaign)
            setIbmAuthenticator(coolCampaign.ibmAuthenticator);
          if ("ibmId" in coolCampaign) setIbmId(coolCampaign.ibmId);
          if ("ibmUrl" in coolCampaign) setIbmUrl(coolCampaign.ibmUrl);
          setContacts(contacts);
          setSelectedContacts(
            contacts.filter(
              (contact) =>
                contact.hasReplied === true &&
                contact.campaignId === goodCampaigns[0].value
            )
          );
          setCurrentContact(contacts[0]);
          setUnlabledContact(
            contacts.filter(
              (contact) => contact.conversationSentiment === null
            )[0]
          );
          setUnlabledContacts(
            contacts.filter((contact) => contact.conversationSentiment === null)
              .length
          );

          if (contacts[0])
            setMessageSentiment(contacts[0].conversationSentiment);
        }
      } catch (e) {
        console.log(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.isAuthenticated, props.accountId, props.campaigns]);

  function updateSentiment(contact) {
    return API.put("contacts", `/updatesentiment`, {
      body: contact,
    });
  }

  async function label(conversationSentiment) {
    setIsLabeling(true);
    unlabledContact["conversationSentiment"] = conversationSentiment;

    const updatedContact = {
      campaignId: unlabledContact.campaignId,
      userId: unlabledContact.userId,
      conversationSentiment,
      templateId: unlabledContact.templateId,
      contactId: unlabledContact.contactId,
      campaignSlug: unlabledContact.campaignSlug,
      linkedinUrl: unlabledContact.linkedinUrl,
    };

    console.log(updatedContact);
    try {
      await updateSentiment(updatedContact);
      let campaigns = props.campaigns.filter(
        (campaign) =>
          campaign.accountId === props.accountId && campaign.conversations > 0
      );
      let contacts = await loadContacts(campaigns[0].campaignId);
      contacts = contacts.filter(
        (contact) =>
          contact.hasReplied === true &&
          "conversationMessages" in contact &&
          contact.conversationMessages.length > 0
      );
      setContacts(contacts);
      setUnlabledContact(
        contacts.filter((contact) => contact.conversationSentiment === null)[0]
      );
      setUnlabledContacts(
        contacts.filter((contact) => contact.conversationSentiment === null)
          .length
      );
      setIsLabeling(false);
    } catch (e) {
      setIsLabeling(false);
      console.log(e);
    }
  }

  async function handleUpdateSentiment(conversationSentiment) {
    setMessageSentiment(conversationSentiment);

    currentContact["conversationSentiment"] = conversationSentiment;
    setCurrentContact(currentContact);

    const updatedContact = {
      campaignId: currentContact.campaignId,
      userId: currentContact.userId,
      conversationSentiment,
      templateId: currentContact.templateId,
      contactId: currentContact.contactId,
      campaignSlug: currentContact.campaignSlug,
      linkedinUrl: currentContact.linkedinUrl,
    };

    try {
      await updateSentiment(updatedContact);
    } catch (e) {
      console.log(e);
    }
  }

  function loadContacts(campaignId) {
    return API.get("contacts", `/campaigns/${campaignId}/contacts`);
  }

  function loadContact(contactId) {
    return API.get("contacts", `/contacts/${contactId}`);
  }
  const pause = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  async function loadConversation() {
    setMessageText("");

    try {
      await axios.post("https://legacy.api.conversify.ai/enqueue", {
        campaignId: currentContact.campaignId,
        accountKey: {
          accountId: currentContact.accountId,
          userId: currentContact.userId,
        },
        commands: [
          {
            action: "loadConversation",
            contactId: currentContact.contactId,
          },
        ],
      });
      let contactUpdateTime;
      do {
        await pause(1000); // wait 20s
        const contact = await loadContact(currentContact.contactId);
        contactUpdateTime = contact.updatedAt;
        setCurrentContact(contact);
      } while (contactUpdateTime === currentContact.updatedAt);

      setMessageLoad(false);
      setIsLoading(false);
    } catch (e) {
      setMessageLoad(false);
      setIsLoading(false);
      console.log(e);
    }
  }

  async function sendMessage(e) {
    if (e.key === "Enter") {
      const firstMessage = { ...currentContact.conversationMessages[0] };
      firstMessage["content"] = messageText;
      firstMessage["createdAt"] = Date.now();

      currentContact.conversationMessages.push(firstMessage);
      setCurrentContact(currentContact);
      setMessageLoad(true);
      setMessageText("");

      try {
        await axios.post(
          "https://legacy.api.conversify.ai/enqueue",
          {
            campaignId: currentContact.campaignId,
            accountKey: {
              accountId: currentContact.accountId,
              userId: currentContact.userId,
            },
            commands: [
              {
                action: "sendMessage",
                contactId: currentContact.contactId,
                params: { content: messageText },
              },
            ],
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "https://www.conversify.ai",
              "Content-Type": "application/json",
            },
          }
        );
        await pause(1000);
        loadConversation();
        setIsLoading(false);
      } catch (e) {
        await pause(1000);
        setMessageLoad(false);
        setIsLoading(false);
        console.log(e);
      }
    }
  }

  function updateContact(contact) {
    setCurrentContact(contact);
    setMessageSentiment(contact.conversationSentiment);
  }

  async function updateCampaign(campaign) {
    setSelectedCampaign(campaign);
    let contacts = await loadContacts(campaign.value);
    console.log(contacts);
    contacts = contacts.filter(
      (contact) =>
        contact.hasReplied === true &&
        contact.campaignId === campaign.value &&
        "conversationMessages" in contact
    );
    console.log(contacts);
    setSelectedContacts(contacts);
    setCurrentContact(contacts[0]);
    let convoS = null;
    if ("conversationSentiment" in contacts[0])
      convoS = contacts[0].conversationSentiment;
    setMessageSentiment(convoS);
  }

  function loadCampaign(campaignId) {
    return API.get("campaigns", `/campaigns/${campaignId}`);
  }

  function finish() {
    setIntegrated(false);
    setUpdateProfileModal(false);
    setLoadingError(false);
    setLoadingIntegration(false);
  }

  function validateForm() {
    return true;
  }

  function saveCampaign() {
    return API.put("campaigns", `/watson/${campaign.campaignId}`, {
      body: {
        ibmId,
        ibmUrl,
        ibmAuthenticator,
        sessionId: campaign.userId,
        campaign: campaign.title,
      },
    });
  }

  async function updateCampaignIntegration() {
    setLoadingIntegration(true);
    try {
      await saveCampaign();
      setLoadingIntegration(false);
      setIntegrated(true);
      let contacts = await loadContacts();
      contacts = contacts.filter(
        (contact) =>
          contact.hasReplied === true &&
          contact.conversationMessages.length > 0 &&
          contact.accountId === props.accountId
      );
      console.log(contacts);
      setContacts(contacts);
      setSelectedContacts(
        contacts.filter(
          (contact) =>
            contact.hasReplied === true &&
            contact.campaignId === campaigns[0].value
        )
      );
      setCurrentContact(contacts[0]);
    } catch (e) {
      setLoadingError(true);
      console.log(e);
    }
  }

  return (
    <div className="Conversations">
      {isLoading ? (
        <Loading />
      ) : contacts && contacts.length === 0 ? (
        <div>
          <Row>
            <Col sm={12} className="text-center">
              <br />
              <br />
              <br />
              <br />
              <h5>Manage Your Inbox</h5>
              <p>
                Trouble starting conversations on LinkedIn? <br /> Schedule a
                call and we will help you use LinkedIn to generate leads.
              </p>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          {!messageLoad ? (
            unlabledContact ? (
              isLabeling ? (
                <Loading />
              ) : (
                <Row>
                  {unlabledContacts > 0 ? (
                    <Col xs={12}>
                      <p className="text-center">
                        <small>
                          You have {unlabledContacts}{" "}
                          {unlabledContacts === 1 ? "response" : "responses"}{" "}
                          left to review
                        </small>
                      </p>
                      <br />
                    </Col>
                  ) : null}

                  <Col smOffset={1} sm={5}>
                    <br />
                    <p>
                      <b>
                        {unlabledContact.firstName} {unlabledContact.lastName}{" "}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={unlabledContact.linkedinUrl}
                        >
                          <img
                            height="15px"
                            alt="linekdin logo"
                            style={{ position: "relative", bottom: "1px" }}
                            src="https://s3.amazonaws.com/adsgen/linkedin.svg"
                          />
                        </a>
                      </b>
                    </p>
                    <p>Is this conversation positive, negative or neutral?</p>
                    <br />
                    <p className="contact-title">
                      {" "}
                      <button
                        onClick={() => label("POSITIVE")}
                        style={{ marginRight: "5px" }}
                        className="other-btn POSITIVE pull-left"
                      >
                        Positive
                      </button>{" "}
                      <button
                        onClick={() => label("NEUTRAL")}
                        style={{ marginRight: "5px" }}
                        className="other-btn NEUTRAL pull-left"
                      >
                        Neutral
                      </button>
                      <button
                        onClick={() => label("NEGATIVE")}
                        className="other-btn NEGATIVE pull-left"
                      >
                        Negative
                      </button>{" "}
                    </p>
                    <br />
                    <a
                      href={unlabledContact.conversationUrl}
                      style={{
                        marginRight: "5px",
                        textDecoration: "none",
                        padding: "20px",
                        paddingLeft: "0px",
                      }}
                      className="step"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IosArrowForward fontSize="16px" color="#4800d8" /> View
                      conversation on LinkedIn
                    </a>
                  </Col>
                  <Col sm={5}>
                    <div className="text-left">
                      <br />
                      {unlabledContact.conversationMessages.map((msg, i) => (
                        <div key={i}>
                          <p>
                            <b>
                              {msg.user.firstName}{" "}
                              {msg.user.lastName !== "none"
                                ? msg.user.lastName
                                : null}{" "}
                            </b>
                            <small>
                              {moment(msg.createdAt).format(
                                "MMM Do, h:mm:ss a"
                              )}
                            </small>
                          </p>
                          <p>{msg.content}</p>
                          <br />
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              )
            ) : (
              <Row>
                <Col sm={3}>
                  <Dropdown
                    value={selectedCampaign}
                    options={campaigns}
                    onChange={updateCampaign}
                    placeholder="Select a campaign"
                  />
                  <div className="contacts-container">
                    {selectedContacts &&
                      selectedContacts.map((contact, i) =>
                        contact.fullName !== "none" ? (
                          <div
                            key={i}
                            onClick={() => updateContact(contact)}
                            className={
                              currentContact.linkedinUsername ===
                              contact.linkedinUsername
                                ? "step active"
                                : "step"
                            }
                          >
                            <h4 className={contact.conversationSentiment}>
                              {contact.firstName}{" "}
                              {contact.lastName !== "none"
                                ? contact.lastName
                                : null}{" "}
                              {contact.conversationMessages[
                                contact.conversationMessages.length - 1
                              ].user.username === contact.linkedinUsername ? (
                                <span className="pull-right">
                                  <MdEye fontSize="16px" color={"#4800d8"} />
                                </span>
                              ) : null}
                              <br />
                              <small>
                                {contact.linkedinHeadline &&
                                contact.linkedinHeadline.length > 30
                                  ? contact.linkedinHeadline.slice(0, 30) +
                                    " ... "
                                  : contact.linkedinHeadline}{" "}
                              </small>
                            </h4>
                          </div>
                        ) : null
                      )}
                  </div>
                </Col>
                <Col sm={9}>
                  <p className="contact-title">
                    {" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={currentContact.linkedinUrl}
                    >
                      <img
                        height="15px"
                        alt="linekdin logo"
                        style={{ position: "relative", bottom: "1px" }}
                        src="https://s3.amazonaws.com/adsgen/linkedin.svg"
                      />
                    </a>{" "}
                    <b>
                      {currentContact.firstName}{" "}
                      {currentContact.lastName !== "none"
                        ? currentContact.lastName
                        : null}{" "}
                    </b>
                    <small>
                      last updated{" "}
                      {moment(currentContact.updatedAt).format("MMM Do")} |{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={currentContact.conversationUrl}
                      >
                        view in LinkedIn {`>`}
                      </a>
                    </small>{" "}
                    {messageSentiment !== "NEGATIVE" ? (
                      <button
                        onClick={() => handleUpdateSentiment("NEGATIVE")}
                        className="other-btn NEGATIVE"
                      >
                        Negative
                      </button>
                    ) : (
                      <span className="other-btn NEGATIVE">Negative</span>
                    )}
                    {messageSentiment !== "NEUTRAL" ? (
                      <button
                        style={{ marginRight: "3px" }}
                        onClick={() => handleUpdateSentiment("NEUTRAL")}
                        className="other-btn NEUTRAL"
                      >
                        Neutral
                      </button>
                    ) : (
                      <span
                        style={{ marginRight: "3px" }}
                        className="other-btn NEUTRAL"
                      >
                        Neutral
                      </span>
                    )}
                    {messageSentiment !== "POSITIVE" ? (
                      <button
                        style={{ marginRight: "3px" }}
                        onClick={() => handleUpdateSentiment("POSITIVE")}
                        className="other-btn POSITIVE"
                      >
                        Positive
                      </button>
                    ) : (
                      <span
                        style={{ marginRight: "3px" }}
                        className="other-btn ispositive"
                      >
                        Positive
                      </span>
                    )}
                  </p>

                  <div className="conversations-inbox text-left">
                    <br />
                    {currentContact.conversationMessages.map((msg, i) => (
                      <div key={i}>
                        <p>
                          <b>
                            {msg.user.firstName}{" "}
                            {msg.user.lastName !== "none"
                              ? msg.user.lastName
                              : null}{" "}
                          </b>
                          <small>
                            {moment(msg.createdAt).format("MMM Do, h:mm:ss a")}
                          </small>
                        </p>
                        <p>{msg.content}</p>
                        <br />
                      </div>
                    ))}

                    <div>
                      {currentContact.conversationMessages[
                        currentContact.conversationMessages.length - 1
                      ].user.username === currentContact.linkedinUsername ? (
                        <div className="suggested-replies">
                          <Row>
                            <Col xs={12}>
                              {currentContact.suggestedReply ? (
                                <div>
                                  <button
                                    className="reply-btn"
                                    onClick={() =>
                                      setMessageText(
                                        currentContact.suggestedReply
                                      )
                                    }
                                  >
                                    {currentContact.suggestedReply}
                                  </button>{" "}
                                  <button
                                    className="reply-btn new-suggestion"
                                    onClick={() => setUpdateProfileModal(true)}
                                  >
                                    Update
                                  </button>
                                </div>
                              ) : (
                                <button
                                  onClick={() => setUpdateProfileModal(true)}
                                  className="reply-btn"
                                >
                                  Want to automate this conversation? Integrate
                                  IBM Watson.
                                </button>
                              )}
                            </Col>
                          </Row>
                        </div>
                      ) : null}
                      <div className="inbox-container">
                        <div className="inbox-border">
                          <Row>
                            <Col xs={12}>
                              <input
                                type="text"
                                value={messageText}
                                onChange={(e) => setMessageText(e.target.value)}
                                onKeyDown={sendMessage}
                                placeholder={`Send ${currentContact.firstName} a message ...`}
                              ></input>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )
          ) : (
            <div>
              <br />
              <br />
              <Row>
                <Col sm={12}>
                  <div className="form-wrapper">
                    <p style={{ opacity: 1, textAlign: "center" }}>
                      <b>
                        Sending your message to {currentContact.firstName} ...
                      </b>
                    </p>
                    <p style={{ opacity: 1, textAlign: "center" }}>
                      Don't touch anything - this will take about a minute.
                    </p>
                    <img
                      style={{ width: "100%" }}
                      alt="loading-gif"
                      src="https://conversify-marketing.s3.amazonaws.com/BrainyLabs.gif"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
      )}
      <Modal show={viewUpdateProfileModal} dialogClassName="modal-90w">
        {loadingError ? (
          <Modal.Body>
            <div className="modal-card text-center" style={{ padding: "20px" }}>
              <Row>
                <Col className="text-center">
                  <p className="text-center">
                    <button onClick={() => finish()} className="other-btn">
                      close
                    </button>
                  </p>
                  <br />
                  <p style={{ opacity: 1, textAlign: "center" }}>
                    <b>Integration Error</b>
                  </p>
                  <p>Don't worry. Schedule a call and we'll help you out.</p>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        ) : loadingIntegration ? (
          <Modal.Body>
            <div className="modal-card text-center" style={{ padding: "20px" }}>
              <Row>
                <Col sm={12}>
                  <p style={{ opacity: 1, textAlign: "center" }}>
                    <b>Integrating this campaign with IBM Watson ...</b>
                  </p>
                  <p style={{ opacity: 1, textAlign: "center" }}>
                    Don't touch anything - this will take about a minute.
                  </p>
                  <img
                    style={{ width: "100%" }}
                    alt="loading-gif"
                    src="https://conversify-marketing.s3.amazonaws.com/BrainyLabs.gif"
                  />
                </Col>
              </Row>
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body>
            {viewTutorial ? (
              <div className="modal-card text-center">
                <Row>
                  <Col xs={12}>
                    <div className="NewTemplate" style={{ paddingTop: "0px" }}>
                      <button
                        onClick={() => finish()}
                        className="other-btn pull-right"
                      >
                        close
                      </button>
                      <button
                        onClick={() => setViewTutorial(false)}
                        className="other-btn pull-left"
                      >
                        back
                      </button>
                    </div>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col xs={12} className="text-center">
                    <div
                      className="NewTemplate text-center"
                      style={{ paddingTop: "0px" }}
                    >
                      <p style={{ opacity: 1, textAlign: "center" }}>
                        <b>Custom IBM Watson Integration</b>
                      </p>
                      <p style={{ opacity: 1, textAlign: "center" }}>
                        Automating conversations on LinkedIn isn't easy but it's
                        possible. Schedule a call and we'll show you how.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="modal-card text-center">
                <Row>
                  <Col xs={12}>
                    <div className="NewTemplate" style={{ paddingTop: "0px" }}>
                      <button
                        onClick={() => finish()}
                        className="other-btn pull-right"
                      >
                        close
                      </button>
                      <button
                        onClick={() => setViewTutorial(true)}
                        className="other-btn pull-left"
                      >
                        ?
                      </button>
                    </div>
                  </Col>
                </Row>
                <div className="Contacts" style={{ paddingTop: "0px" }}>
                  {integrated ? (
                    <div className="text-center">
                      <h1>
                        <MdCheckmark fontSize="60px" color="#4800d8" />
                      </h1>
                      <p style={{ textAlign: "center" }}>
                        <b>IBM Credentials Added</b>
                      </p>
                      <p style={{ textAlign: "center" }}>
                        Now you have access to suggested replies.
                      </p>
                    </div>
                  ) : (
                    <Row>
                      <Col sm={12}>
                        <FormGroup controlId="ibmId">
                          <ControlLabel>Assistant Id</ControlLabel>
                          <FormControl
                            value={ibmId}
                            type="text"
                            placeholder="6c703fd7-e1e0-49c4-9921-fde9372008eb"
                            onChange={(e) => setIbmId(e.target.value)}
                          />
                        </FormGroup>
                        <FormGroup controlId="ibmAuthenticator">
                          <ControlLabel>Assistant API Key</ControlLabel>
                          <FormControl
                            value={ibmAuthenticator}
                            type="text"
                            placeholder="HJR5LyOq_jpftbVPT45AytOdYI3E-8Xmn6PcLz8MXgxY"
                            onChange={(e) =>
                              setIbmAuthenticator(e.target.value)
                            }
                          />
                        </FormGroup>
                        <FormGroup controlId="ibmUrl">
                          <ControlLabel>Workspace URL</ControlLabel>
                          <FormControl
                            value={ibmUrl}
                            type="text"
                            placeholder="https://api.us-south.assistant.watson.cloud.ibm.com/instances/720b0e31-ea71-4283-870c-1dc37f1eee45/"
                            onChange={(e) => setIbmUrl(e.target.value)}
                          />
                        </FormGroup>
                        <LoaderButton
                          type="submit"
                          className="other-btn pull-right"
                          isLoading={isLoading}
                          disabled={!validateForm()}
                          onClick={() => updateCampaignIntegration()}
                        >
                          Update
                        </LoaderButton>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            )}
          </Modal.Body>
        )}
      </Modal>
    </div>
  );
}
