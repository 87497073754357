import React, { useEffect, useState } from "react";

import IosRefresh from "react-ionicons/lib/IosRefresh";
import NewAccount from "./NewAccount";

export default function Onboarding(props) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      try {
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, []);

  return (
    <div className="">
      {isLoading ? (
        <div className="container text-center">
          <br />
          <br />
          <br />
          <br />
          <IosRefresh fontSize="60px" color="#4800d8" rotate={true} />
        </div>
      ) : (
        <div>
          <br />
          <br />
          <div className="form-wrapper">
            <NewAccount props={props} />
          </div>
        </div>
      )}
    </div>
  );
}
