import "./Accounts.css";

import { Col, Modal, Row, Table } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import { API, Auth } from "aws-amplify";
import Broadcast from "./Broadcast";
import Campaigns from "./Campaigns";
import Contacts from "./Contacts";
import Conversations from "./Conversations";
import IosApps from "react-ionicons/lib/IosApps";
import IosArrowDown from "react-ionicons/lib/IosArrowDown";
import IosArrowForward from "react-ionicons/lib/IosArrowForward";
import IosChatboxes from "react-ionicons/lib/IosChatboxes";
import IosArrowUp from "react-ionicons/lib/IosArrowUp";
import IosSettings from "react-ionicons/lib/IosSettings";
import MdAdd from "react-ionicons/lib/MdAdd";
import MdMegaphone from "react-ionicons/lib/MdMegaphone";
import IosFunnel from "react-ionicons/lib/IosFunnel";
import NewAccount from "./NewAccount";
import Settings from "./Settings";
import Switch from "react-switch";
import Templates from "./Templates";
import ViewCampaign from "./ViewCampaign";
import moment from "moment";

export default function Accounts(props) {
  const [accounts, setAccounts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [account, setAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showAccountList, setAccountLimit] = useState(false);
  const [screen, setScreen] = useState("home");
  const [viewAddAaccountModal, setViewAddAccountModal] = useState(false);
  const [viewPaymentModal, setViewPaymentModal] = useState(false);
  const [viewReferFriend, setViewReferFriend] = useState(false);
  const [viewCampaignModal, setViewCampaignModal] = useState(false);
  const [campaignId, setCampaignId] = useState(null);
  const [showNavMenu, setShowNavMenu] = useState(false);
  const [profile, setProfile] = useState(false);

  useEffect(() => {
    function loadProfile(userId) {
      return API.get("profiles", `/myprofile/${userId}`);
    }

    async function onLoad() {
      try {
        const accounts = await loadAccounts();
        const campaigns = await loadCampaigns();

        const account = accounts.filter(
          (account) => account.accountId === props.accountId
        )[0];
        setCampaigns(campaigns);
        setAccount(account);
        setAccounts(accounts);
        const user = await Auth.currentUserInfo();
        const profile = await loadProfile(user["id"]);
        setProfile(profile);
      } catch (e) {
        alert(e);
      }

      setIsLoading(false);
    }

    onLoad();
  }, [props.accountId]);

  function loadCampaigns() {
    return API.get("campaigns", "/campaigns");
  }

  function loadAccounts() {
    return API.get("accounts", "/accounts");
  }

  function accountsOverlay() {
    setAccountLimit(true);
  }

  function deleteAccount() {
    return API.del("accounts", `/accounts/${account.accountId}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this account?"
    );

    if (!confirmed) {
      return;
    }

    setIsLoading(true);

    try {
      await deleteAccount();
      props.props.history.push(`/`);
    } catch (e) {
      alert(e);
      setIsLoading(false);
    }
  }

  function changeScreen(newScreen) {
    setViewPaymentModal(false);
    setViewAddAccountModal(false);
    setScreen(newScreen);
  }

  function saveCampaign(campaign) {
    return API.put("campaigns", `/campaigns/${campaign.campaignId}`, {
      body: campaign,
    });
  }

  function showViewCampaignModal(campaignId) {
    setCampaignId(campaignId);
    setViewCampaignModal(true);
  }

  async function handleActivateCampaign(campaign) {
    const updatedCampaign = {
      title: campaign.title,
      dailyLimit: campaign.dailyLimit,
      paused: !campaign.paused,
      slug: campaign.slug,
      accountId: campaign.accountId,
      campaignId: campaign.campaignId,
      username: campaign.username,
      userId: campaign.userId,
    };

    try {
      await saveCampaign(updatedCampaign);
    } catch (e) {
      alert(e);
    }
  }

  function renderAccounts() {
    return (
      <div className="Accounts">
        <div className="accounts-header">
          <Row>
            <Col sm={8}>
              <img
                style={{ position: "absolute", top: "0px" }}
                alt="header times"
                height="40px"
                src="https://conversify-marketing.s3.amazonaws.com/brainylabs.png"
              />
            </Col>
            <Col sm={4}>
              <Row>
                <Col xs={6}></Col>
                <Col xs={6}>
                  <div
                    onClick={() => setShowNavMenu(!showNavMenu)}
                    className="nav-menu"
                  >
                    <p>
                      <b>
                        {profile.firstName} {profile.lastName}
                      </b>{" "}
                      {showNavMenu ? (
                        <IosArrowUp fontSize="16px" />
                      ) : (
                        <IosArrowDown fontSize="16px" />
                      )}{" "}
                    </p>
                  </div>
                  {showNavMenu ? (
                    <div className="nav-dropdown">
                      <ul>
                        {accounts.length > 1 ? (
                          <li
                            onClick={() => {
                              setScreen("home");
                              setShowNavMenu(false);
                            }}
                          >
                            {" "}
                            All Campaigns{" "}
                          </li>
                        ) : null}
                        <li
                          onClick={() => {
                            setScreen("templates");
                            setShowNavMenu(false);
                          }}
                        >
                          Templates
                        </li>
                        <li
                          onClick={() => {
                            setScreen("profile");
                            setShowNavMenu(false);
                          }}
                        >
                          Subscription
                        </li>
                        <li
                          onClick={() =>
                            window.open(
                              "https://www.brainylabs.io/tutorials-resources",
                              "_blank"
                            )
                          }
                        >
                          Tutorials
                        </li>
                        <li onClick={() => props.props.handleLogout()}>
                          Logout
                        </li>
                      </ul>
                    </div>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="accounts-body">
          <Row>
            <Col sm={2}>
              {!isLoading ? (
                <div>
                  <div className="accountsColumn">
                    <h3 onClick={() => accountsOverlay()}>
                      <span className="circle">
                        <IosApps fontSize="16px" />
                      </span>
                      {account.firstName} {account.lastName}{" "}
                      <IosArrowDown fontSize="16px" />
                    </h3>

                    {showAccountList ? (
                      <div
                        className="accountsOverlay"
                        onMouseLeave={() => setAccountLimit(false)}
                      >
                        {accounts
                          .filter((account) => account.firstName === "none")
                          .map((account) => (
                            <a
                              style={{ textDecoration: "none" }}
                              href={`/accounts/${account.accountId}`}
                              key={account.accountId}
                            >
                              <h3>
                                <span className="circle">
                                  <IosApps fontSize="16px" />
                                </span>
                                Unverified
                              </h3>
                            </a>
                          ))}
                        {accounts
                          .filter((account) => account.firstName !== "none")
                          .map((account) => (
                            <a
                              style={{ textDecoration: "none" }}
                              href={`/accounts/${account.accountId}`}
                              key={account.accountId}
                            >
                              <h3>
                                <span className="circle">
                                  <IosApps fontSize="16px" />
                                </span>
                                {account.firstName} {account.lastName}{" "}
                              </h3>
                            </a>
                          ))}
                        <h3 onClick={() => setViewAddAccountModal(true)}>
                          <span className="circle">
                            <MdAdd fontSize="16px" />
                          </span>
                          Add Account
                        </h3>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}

              <br />
              <h4
                onClick={() => setScreen("campaigns")}
                className={screen === "campaigns" ? "active" : null}
              >
                <IosFunnel
                  fontSize="16px"
                  color={screen === "campaigns" ? "#4800d8" : null}
                />
                Campaigns
              </h4>
              <h4
                onClick={() => setScreen("conversations")}
                className={screen === "conversations" ? "active" : null}
              >
                <IosChatboxes
                  fontSize="16px"
                  color={screen === "conversations" ? "#4800d8" : null}
                />
                Inbox
              </h4>
              <h4
                onClick={() => setScreen("broadcast")}
                className={screen === "broadcast" ? "active" : null}
              >
                <MdMegaphone
                  fontSize="16px"
                  color={screen === "broadcast" ? "#4800d8" : null}
                />
                Broadcast
              </h4>
              <h4
                onClick={() => setScreen("settings")}
                className={screen === "settings" ? "active" : null}
                style={{ marginTop: "0px" }}
              >
                <IosSettings
                  fontSize="16px"
                  color={screen === "settings" ? "#4800d8" : null}
                />
                Settings
              </h4>
            </Col>
            <Col sm={10}>
              <div className="cardComponent">
                {screen === "home" && account ? (
                  campaigns.length > 0 ? (
                    <div>
                      <Row>
                        <Col sm={3}>
                          <h1>
                            {campaigns.reduce(
                              (a, b) => a + (b.requests || 0),
                              0
                            )}{" "}
                            <small>Requests</small>
                          </h1>
                        </Col>
                        <Col sm={3}>
                          <h1>
                            {campaigns.reduce(
                              (a, b) => a + (b.connections || 0),
                              0
                            )}{" "}
                            <small>Connections</small>
                          </h1>
                        </Col>
                        <Col sm={3}>
                          <h1>
                            {campaigns.reduce(
                              (a, b) => a + (b.conversations || 0),
                              0
                            )}{" "}
                            <small>Replies</small>
                          </h1>
                        </Col>
                        <Col sm={3}>
                          <h1>
                            {campaigns.reduce(
                              (a, b) => a + (b.positives || 0),
                              0
                            )}{" "}
                            <small>Positive Replies</small>
                          </h1>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <h5>Templates</h5>
                        </Col>
                      </Row>
                      <Templates setScreen={setScreen} props={props} />
                      <Row>
                        <Col sm={12}>
                          <h5>Campaigns</h5>
                        </Col>
                      </Row>

                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Date</th>
                            <th>Account</th>
                            <th>Sending Limit</th>
                            <th>Contacts</th>
                            <th>Requests</th>
                            <th>Connections</th>
                            <th>Replies</th>
                            <th>Positive Replies</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {campaigns
                            .sort((a, b) => b.createdAt - a.createdAt)
                            .map((campaign, i) => (
                              <tr key={campaign.campaignId}>
                                <td
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    showViewCampaignModal(campaign.campaignId)
                                  }
                                >
                                  {campaign.title}
                                </td>
                                <td>
                                  {moment(campaign.createdAt).format("MMM Do")}
                                </td>
                                <td>{campaign.username}</td>
                                <td>{campaign.dailyLimit} </td>
                                <td>{campaign.contacts}</td>
                                <td>
                                  {campaign.requests}{" "}
                                  {campaign.contacts > 0 &&
                                  campaign.requests > 0 ? (
                                    <small className="blue">
                                      {parseInt(
                                        (campaign.requests /
                                          campaign.contacts) *
                                          100
                                      )}
                                      %
                                    </small>
                                  ) : null}
                                </td>
                                <td>
                                  {campaign.connections}{" "}
                                  {campaign.contacts > 0 &&
                                  campaign.connections > 0 ? (
                                    <small className="blue">
                                      {parseInt(
                                        (campaign.connections /
                                          campaign.contacts) *
                                          100
                                      )}
                                      %
                                    </small>
                                  ) : null}
                                </td>
                                <td>
                                  {campaign.conversations}{" "}
                                  {campaign.contacts > 0 &&
                                  campaign.conversations ? (
                                    <small className="blue">
                                      {parseInt(
                                        (campaign.conversations /
                                          campaign.contacts) *
                                          100
                                      )}
                                      %
                                    </small>
                                  ) : null}
                                </td>
                                <td>
                                  {campaign.positives}{" "}
                                  {campaign.contacts > 0 &&
                                  campaign.positives > 0 ? (
                                    <small className="blue">
                                      {parseInt(
                                        (campaign.positives /
                                          campaign.contacts) *
                                          100
                                      )}
                                      %
                                    </small>
                                  ) : null}
                                </td>
                                <td>
                                  {campaign.requests > 0 ? (
                                    <Switch
                                      height={20}
                                      width={35}
                                      onChange={() =>
                                        handleActivateCampaign(campaign)
                                      }
                                      onColor={"#4800d8"}
                                      checked={!campaign.paused}
                                    />
                                  ) : (
                                    <span className="blue">
                                      {campaign.paused === true
                                        ? "Paused"
                                        : "Active"}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <Row>
                      <Col md={4} sm={6} xs={12}>
                        <div className="home">
                          <h2>Follow these steps to get started</h2>
                          <div
                            onClick={() => setScreen("templates")}
                            className="step"
                          >
                            <p>
                              <IosArrowForward
                                fontSize="16px"
                                color="#4800d8"
                              />{" "}
                              1. Create a template <small>(3 min)</small>
                            </p>
                          </div>
                          <div
                            onClick={() => setScreen("campaigns")}
                            className="step"
                          >
                            <p>
                              <IosArrowForward
                                fontSize="16px"
                                color="#4800d8"
                              />{" "}
                              2. Start a campaign <small>(2 min)</small>
                            </p>
                          </div>
                          <div
                            onClick={() => setScreen("conversations")}
                            className="step"
                          >
                            <p>
                              <IosArrowForward
                                fontSize="16px"
                                color="#4800d8"
                              />{" "}
                              3. Manage your inbox <small>(10 min daily)</small>
                            </p>
                          </div>
                          <div
                            onClick={() => setScreen("broadcast")}
                            className="step"
                          >
                            <p>
                              <IosArrowForward
                                fontSize="16px"
                                color="#4800d8"
                              />{" "}
                              4. Broadcast a message
                            </p>
                          </div>
                          <div
                            onClick={() => setScreen("profile")}
                            className="step"
                          >
                            <p>
                              <IosArrowForward
                                fontSize="16px"
                                color="#4800d8"
                              />{" "}
                              5. Upgrade your account
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )
                ) : null}

                {screen === "contacts" ? (
                  <Contacts props={props} accountId={props.accountId} />
                ) : null}
                {screen === "campaigns" ? (
                  <Campaigns
                    changeScreen={changeScreen}
                    props={props}
                    accountId={props.accountId}
                    campaigns={campaigns}
                  />
                ) : null}
                {screen === "conversations" ? (
                  <Conversations
                    campaigns={campaigns}
                    props={props}
                    accountId={props.accountId}
                  />
                ) : null}
                {screen === "broadcast" ? (
                  <Broadcast
                    campaigns={campaigns}
                    props={props}
                    accountId={props.accountId}
                  />
                ) : null}
                {screen === "templates" ? (
                  <Templates setScreen={setScreen} props={props} />
                ) : null}

                {screen === "profile" ? (
                  <div>
                    <Row>
                      <Col sm={12} className="text-center">
                        <br />
                        <br />
                        <br />
                        <br />
                        <h5>Reach more customers on LinkedIn</h5>
                        <p>
                          Want to send more messages? <br /> Add another
                          LinkedIn account to Brainylabs.
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col smOffset={3} sm={6}>
                        <div className="s">
                          <button
                            className="btn-secondary"
                            onClick={() => setViewPaymentModal(true)}
                          >
                            Upgrade
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col smOffset={3} sm={6}>
                        <br />
                        <br />
                        <br />
                        <br />
                        <p className="text-center blue">
                          <small>
                            *We charge $45 USD per month for each account you
                            add.
                          </small>
                        </p>
                      </Col>
                    </Row>
                  </div>
                ) : null}

                {screen === "tutorials" ? (
                  <div>
                    <Row>
                      <Col sm={12} className="text-center">
                        <br />
                        <br />
                        <br />
                        <br />
                        <h5>Tutorials</h5>
                        <p>
                          Having trouble getting started? <br /> Watch our video
                          guides to get the most out of LeadLink.
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col smOffset={4} sm={4}>
                        <div className="s">
                          <a
                            className="btn-secondary"
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://youtube.com/channel/UC9HYW8pMkt2zOGAnGvf4lpg"
                          >
                            View tutorials
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : null}

                {screen === "settings" ? (
                  <Row>
                    <Col sm={6}>
                      <div className="home">
                        <h2>
                          Settings for {account.firstName} {account.lastName}'s
                          account
                        </h2>
                        <div
                          onClick={() =>
                            props.props.history.push(
                              `/accounts/${props.accountId}/edit`
                            )
                          }
                          className="step"
                        >
                          <p>
                            <IosArrowForward fontSize="16px" color="#4800d8" />{" "}
                            Update their LinkedIn password
                          </p>
                        </div>
                        <div onClick={handleDelete} className="step">
                          <p>
                            <IosArrowForward fontSize="16px" color="#4800d8" />{" "}
                            Delete their account
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : null}
              </div>
            </Col>
          </Row>
        </div>
        <Modal
          show={viewAddAaccountModal}
          onHide={() => setViewAddAccountModal(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center">
              <NewAccount
                changeScreen={changeScreen}
                history={props.props.history}
              />
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={viewPaymentModal}
          onHide={() => setViewPaymentModal(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center">
              <Settings setScreen={setScreen} props={props} />
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={viewReferFriend}
          onHide={() => setViewReferFriend(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center" style={{ padding: "20px" }}>
              <Row>
                <Col className="text-center">
                  <p style={{ opacity: 1, textAlign: "center" }}>
                    <b>Your Referral Link</b>
                  </p>
                  <p className="referLink">{`https://app.conversify.ai/r/${props.accountId}`}</p>
                  <p>
                    We'll refund you one month's payment for each customer you
                    refer.
                  </p>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={viewCampaignModal}
          onHide={() => setViewCampaignModal(false)}
          dialogClassName="modal-90w"
        >
          <Modal.Body>
            <div className="modal-card text-center">
              <ViewCampaign
                changeScreen={props.changeScreen}
                props={props}
                campaignId={campaignId}
              />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }

  return <div className="">{renderAccounts()}</div>;
}
